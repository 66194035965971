<template>
<div class="page-size page-concept">
  <h1>{{page.titre1.val}}</h1>
  <hr>
  <div>
    <p v-html="page.paragraphe1.val"></p>
    <p v-html="page.paragraphe2.val"></p>
    <p v-html="page.paragraphe3.val"></p>
  </div>
</div>
</template>

<script>
export default {
  name: "page-concept",
  data(){
    return{
      /**
       * @type {RoyaumeRecord}
       */
      page:this.$db.getRecordByIdentifiant("concept")
    }
  }
}
</script>

<style lang="less">
.page-concept{
  --page-w:1570px;
  --page-h:900px;

  h1{
    text-align: center;
    font-size: 80px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  hr{
    border: none;
    border-top: 2px solid #fff;
    margin-bottom: 50px;
    margin-top: 25px;
  }
  >div{
    display: flex;
    justify-content: space-between;
    >p{
      width: 480px;
    }
  }
}
</style>