<template>
<g class="svg-royaume"
    :class="{
      'active':r && r.isActive
    }"

>
  <a href="#" @click="click">
    <slot/>
  </a>

</g>
</template>

<script>
export default {
  name: "svg-royaume-container",
  props:{
    identifiant:{
      type:String
    },
  },
  computed:{
    /**
     *
     * @return {RoyaumeRecord|null}
     */
    r(){
      return this.$db.getRecordByIdentifiant(this.identifiant);
    }
  },
  methods:{
    click(){
      console.log("click",this.r)
      this.$router.push(this.r.url);
    }
  }
}
</script>

<style lang="less">
.svg-royaume{
  path,polygon{
    transition:fill 0.5s;
  }
  &:hover{
    path,polygon{
      fill: #E81C25;
    }
  }
  &.active{
    path,polygon{
      fill: #E81C25;
    }
  }
}
</style>