<template>
<div class="page-size page-royaume mdc-elevation--z10"
     :class="{}">
  <div class="left-right">
    <div class="right">
      <h1 v-html="page.royaume.val"
          :class="{
        'long-text':page.royaume.val.length>20
          }"/>
      <div class="texts">

        <h2 style="--delay:0.25s" v-if="page.titre1.val">
          <span>
               <span v-html="page.titre1.val"/>
          </span>
        </h2>
        <p style="--delay:0.5s" v-html="page.paragraphe1.val"/>

        <h2 style="--delay:0.75s" v-if="page.titre2.val">
          <span>
            <span v-html="page.titre2.val"/>
          </span>
        </h2>
        <p style="--delay:1.0s" v-html="page.paragraphe2.val" v-if="page.paragraphe2.val"/>

        <h2 v-if="page.titre3.val">
          <span>
            <span v-html="page.titre3.val"/>
          </span>
        </h2>
        <p v-html="page.paragraphe3.val" v-if="page.paragraphe3.val"/>

      </div>
    </div>
  </div>
  <nav>
    <a class="close" href="#"
       @click.prevent="$router.push({name:'royaumes',params:{lang:$lang.currentLocale.code}})">
      <icon-base size="150px" icon-color="var(--text-color)">
        <icon-close/>
      </icon-base>
    </a>
  </nav>
</div>
</template>

<script>
import IconBase from "@/icons/IconBase";
import IconClose from "@/icons/IconClose";
export default {
  name: "page-royaume",
  components: { IconClose, IconBase},
  data(){
    return {
      zoomed:false
    }
  },
  computed:{
    /**
     * La page en cours
     * @return {RoyaumeRecord}
     */
    page(){
      return this.$db.getRecordByIdentifiant(this.$route.params.identifiant);
    }
  },
}
</script>

<style lang="less">
.page-royaume{

  pointer-events: none;
  h1{
    font-size: 100px;
    margin-left: 130px;
    margin-top: 130px;
    margin-right: 130px;
    &.long-text{
      font-size: 60px;
    }
    opacity: 0;
    animation-name: kf-fade;
    animation-iteration-count: 1;
    animation-duration: 1s;
    transform-origin: top;
    animation-fill-mode: forwards;
  }
  h2{
    font-size: 30px;
  }
  p{
    padding-bottom: 40px;
    font-size: 18px;
    line-height: 1.3;
    opacity: 0;
    animation-name: kf-fade;
    animation-delay: var(--delay);
    animation-iteration-count: 1;
    animation-duration: 1s;
    transform-origin: top;
    animation-fill-mode: forwards;
    @keyframes kf-fade{
      from{
        transform: translateY(-30px);
        opacity: 0;
      }
      to{
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  .texts{

    margin-left: 130px;
    margin-right: 130px;
    padding-left: 60px;
    position: relative;


    //lgne blanche
    &:before{
      content: "";
      border-left: 1px solid #fff;
      position: absolute;
      height: calc( 100% - 30px );
      left: 0;
      top: 30px;
      transform: scale(0);
      animation-name: kf-entree;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-duration: 3s;
      transform-origin: top;
      animation-fill-mode: forwards;
    }

    --sz:20px;
    h2{
      position: relative;

      //losange
      &:before{
        position: absolute;
        content: "";
        display: block;
        width: var(--sz);
        height: var(--sz);
        border: 1px solid #fff;
        background-color: #B72238;
        top: 5px;
        left: -71px;
        transform: scale(0);
        animation-name: kf-corners-2;
        animation-delay: var(--delay,0s);
        animation-iteration-count: 1;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        @keyframes kf-corners-2{
          from{
            transform: rotate(45deg) scale(0);
          }
          to{
            transform: rotate(45deg) scale(1);
          }
        }
      }

      >span{
        display: inline-block;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        >span{
          display: inline-block;
          animation-name: kf-show2;
          animation-iteration-count: 1;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: var(--delay,0s);
          opacity: 0;
          @keyframes kf-show2{
            from{
              transform: translateY(-60px);
              opacity: 0;
            }
            to{
              transform: translateY(0px);
              opacity: 1;
            }
          }
        }
      }


    }
  }



  .left-right{
    width: 100%;
    height: 100%;
    position: relative;
    .left,.right{
      position: absolute;
      height: 100%;
      width: 50%;
    }
    .right{
      pointer-events: auto;
      background-image: url('../assets/bg-textes.jpg');
      background-position: top right;
      right: 0;
      overflow-y: overlay;
    }
  }

  nav{
    --icon-size:60px;
    pointer-events: none;
    *{
      pointer-events: auto;
    }
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    svg{
      pointer-events: none;
    }
    >*{
      position: absolute;
      color: #fff;
    }
    .close{
      width: calc( var(--icon-size) * 1.5);
      height: calc( var(--icon-size) * 1.5);
      top: 56px;
      right: 40px;
      >svg{
        width: 100%;
        height: 100%;
      }

      transform: scale(0);
      animation-name: kf-close;
      animation-iteration-count: 1;
      animation-duration: 1.0s;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
      @keyframes kf-close{
        from{
          transform: rotate(90deg) scale(0);
        }
        to{
          transform: rotate(0deg) scale(1);
        }
      }

    }
  }
}
</style>