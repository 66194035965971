<template>
  <div id="app">
    <veille v-if="$layout.veille"/>
    <transition name="fade">
      <carte
          class="page-size"
          v-if="$layout.showCarte"/>
    </transition>
    <transition name="fade">
      <router-view :key="$route.fullPath"/>
    </transition>
    <transition name="fade">
      <menu-pills
          id="menu"
          v-if="$layout.showMenu"/>
    </transition>

    <transition name="fade">
      <a id="btn-home" href="#"
         v-if="!$layout.isHome"
         @click.prevent="$router.push('/')">
        <icon-base size="3vw" icon-color="var(--text-color)">
          <icon-home/>
        </icon-base>
      </a>
    </transition>

  </div>
</template>

<script>

import Carte from "@/components/carte";
import IconHome from "@/icons/IconHome";
import IconBase from "@/icons/IconBase";
import MenuPills from "@/components/menu-pills";
import Veille from "@/components/veille";
export default {
  name: 'App',
  components: {
    Veille,
    MenuPills,
    IconBase,
    IconHome,
    Carte,
  },
  data(){
    return{
      "transitionIn":"fadeIn",
      "transitionOut":"fadeOut",
      transiParams:{
        duration:1
      }

    }
  },
  onIdle() {
    console.log("paused")
    this.$layout.veille=true;
    this.$layout.goHome();
  },
  onActive() {
    this.$layout.veille=false;
    console.log("active")
  },
  mounted() {
    this.updatePageActive();
  },
  watch:{
    $route(){
      this.updatePageActive();
    }
  }, methods:{
    updatePageActive(){
      this.$layout.currenRoute=this.$route;
    }
  },

}
</script>

<style lang="less">
@import "style-guide/typographie";
@import "~@material/elevation/dist/mdc.elevation.min.css";
@import "~animate.css";
html,body,*{
  box-sizing: border-box;
}
body{

  --page-w:1920px;
  --page-h:1080px;

  --nav-height:70px;

  height: 100vh;
  background-color: #491114;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./assets/bg-royaumes.jpg");
  color: #fff;
  overflow: hidden;
}
#app {
  //reset----------------------
  width: 100%;
  height: 100%;
  .page-size{
    position: absolute;
    left: calc(50vw - var(--page-w) / 2);
    top: calc(50vh - var(--page-h) / 2);
    width: var(--page-w);
    height: var(--page-h);
  }
  .nav-dates{
    //outline: 1px solid red;
    height: var(--nav-height);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }

  #carte{

  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  #btn-home{
    color: #fff;
    position: absolute;
    --icon-size:60px;
    width: var(--icon-size);
    height: var(--icon-size);
    top: calc(50vh - var(--page-h) / 2 + 56px);
    left: calc(50vw - var(--page-w) / 2 + 56px);
    border: 1px solid #fff;
    border-radius: 100%;
    svg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-2px) translateX(1px);
    }
  }

  #menu{
    position: absolute;
    bottom: calc( 50vh - ( 1080px / 2)  + 30px );
    left: calc(50vw - var(--menu-w) / 2);
  }



}

html,body{
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0,0.3);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(255, 0, 0, 0);

  }

}
</style>
