import Vue from 'vue'
import VueRouter from "vue-router";
import PageRoyaume from "../pages/page-royaume";
import PageRoyaumes from "../pages/page-royaumes";
import PageLanguages from "../pages/page-langues";
import PageConcept from "../pages/page-concept";
import PageEvolution from "../pages/page-evolution";

const router = new VueRouter({
    routes: [
        {
            name:"home",
            path: '/',
            component: PageLanguages
        },
        {
            name:"royaume",
            path: '/:lang/royaume/:identifiant',
            component: PageRoyaume
        },
        {
            name:"royaumes",
            path: '/:lang/royaumes',
            component: PageRoyaumes
        },
        {
            name:"concept",
            path: '/:lang/concept',
            component: PageConcept
        },
        {
            name:"evolution",
            path: '/:lang/evolution',
            component: PageEvolution
        },

    ]
});
Vue.use(VueRouter);
export default router;