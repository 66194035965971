<template>
<div class="page-size page-evolution">
  <div class="images">
    <img v-for="date of images"
         :class="{active:date.active}"
         :src="date.image"
         :key="date.image"/>
  </div>
  <div class="nav">
    <a href="#" :key="date.label"
       :class="{active:date.active}"
       @click="active(date)"
       v-for="date of images">
      {{date.label}}
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: "page-evolution",
  data(){
    return{
      canPlay:false,
      images:[
        {
          label:"1657",
          image:`carte/${this.$lang.currentLocaleCode}/0.png`,
          active:true
        },
        {
          label:"1886",
          image:`carte/${this.$lang.currentLocaleCode}/1.png`,
          active:false
        },
        {
          label:"1908",
          image:`carte/${this.$lang.currentLocaleCode}/2.png`,
          active:false
        },
        {
          label:"1958",
          image:`carte/${this.$lang.currentLocaleCode}/3.png`,
          active:false
        },
        {
          label:"1969",
          image:`carte/${this.$lang.currentLocaleCode}/4.png`,
          active:false
        },
        {
          label:"1971",
          image:`carte/${this.$lang.currentLocaleCode}/5.png`,
          active:false
        },
        {
          label:"2000",
          image:`carte/${this.$lang.currentLocaleCode}/6.png`,
          active:false
        },
      ]
    }
  },
  methods:{
    active(image){
      for(let im of this.images){
        im.active=false;
      }
      image.active=true;
    }
  },
}
</script>

<style lang="less">
.page-evolution{
  .images{
    position: absolute;
    top: calc( 1080px / 2 - 400px - 45px);
    left: calc( 1920px / 2 - 750px );
    width: 1500px;
    height: 815px;
    //border: 1px solid #fff;
    >*{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      transition: all 1s;
      opacity:0;
      filter: saturate(1.2);
      &.active{
        opacity: 1;
        filter: saturate(1);
      }
    }
  }
  .nav{
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      border-top: 1px solid #fff;
    }
    //outline: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: calc( 1080px  - 205px);
    >a{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #C2AD43;
      color: #fff;
      filter: brightness(0.4);
      font-family: var(--typo-metamorphous);

      text-align: center;
      text-decoration: none;
      margin: 30px;
      font-size: 24px;
      width: 130px;
      height: 50px;
      flex-grow: 0;
      flex-shrink: 0;
      &.active{
        filter: brightness(1);
      }
    }
  }
}
</style>