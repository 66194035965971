<template>
    <nav class="languages" >
        <div class="trait"></div>
        <btn-flag v-for="locale of $lang.locales"
                  :locale="locale"
                  :key="locale.code"
                  @change="onChange"
        />
    </nav>
</template>

<script>
    import BtnFlag from "./BtnFlag";
    export default {
        name: "languages",
        components: {
            BtnFlag
        },
        methods:{
            onChange(langCode){
              this.$lang.currentLocale=langCode;
              this.$layout.goConcept();
            }
        }
    }
</script>

<style lang="less">

.languages{
    position: relative;
    .trait{
        position: absolute;
        left: calc( 50% - 500px);
        top: 50px;
        width: 1000px;
        height: 1px;
        background-color: white;
        display: block;
        @sz:7px;
        &:before,&:after{
            position: absolute;
            top: calc( -1 * @sz);
            display: block;
            content: "";
            width: 0;
            height: 0;
            border-top: @sz solid transparent;
            border-bottom: @sz solid transparent;
            border-left: @sz solid white;
        }
        &:before{
            left: 0;
        }
        &:after{
            right: 0;
            border-left: none;
            border-right: @sz solid white;
        }
    }
}


</style>