import RoyaumeRecord from "@/models/RoyaumeRecord";

const axios = require('axios').default;

export default class Db{
    constructor() {
        /**
         *
         * @type {RoyaumeRecord[]}
         */
        this.royaumesRecords=[];
    }

    /**
     *
     * @param identifiant
     * @return {RoyaumeRecord|null}
     */
    getRecordByIdentifiant(identifiant){
        let r=window.$db.royaumesRecords.find(
            r=> r.identifiant.toLowerCase() === identifiant.toLowerCase()
        );
        if(!r){
            console.warn("record "+identifiant+" introuvable")
        }else{
            return r;
        }
    }

    /**
     * Monte la bdd à partir du json transmis
     * @param json
     */
    mount(json){
        for(let d of json){
            d=new RoyaumeRecord(d);
            this.royaumesRecords.push(d);
        }
    }
    importLocal(cb){
        this.mount(require("./data.json"));
        cb();
    }
    importWeb(cb){
        let me=this;
        axios.post(`https://mada-royaumes.pixel-vinaigrette.com/php/data.php`,
            {rand:Math.random()}
        )
            .then(function (response) {
                console.log(response.data);
                me.mount(response.data);
                cb();
            })
            .catch(function (error) {
                console.error(error);
            })
            .then(function () {
                // always executed
            });
    }

}