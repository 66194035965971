<template>
<nav class="menu-pills">

  <div class="line">
    <i/>
    <i/>
  </div>

  <a href="#"
     :class="{'active':$layout.isConcept}"
     @click="$layout.goConcept()">
    {{$db.getRecordByIdentifiant("menu").titre1.val}}
  </a>
  <a href="#"
     :class="{'active':$layout.isEvolution}"
     @click="$layout.goEvolution()">
    {{$db.getRecordByIdentifiant("menu").titre2.val}}
  </a>
  <a href="#"
     :class="{'active':$layout.isRoyaumes}"
     @click="$layout.goRoyaumes()">
    {{$db.getRecordByIdentifiant("menu").titre3.val}}
  </a>
</nav>
</template>

<script>
export default {
  name: "menu-pills",
}
</script>

<style lang="less">
.menu-pills{
  --menu-w:1780px;
  --menu-h:60px;
  position: absolute;
  width: var(--menu-w);
  height: var(--menu-h);
  display: flex;
  justify-content: center;
  align-items: center;
  >a{
    z-index: 1;
    text-decoration: none;
    margin: 0 25px;
    font-size: 16px;
    font-family: var(--typo-metamorphous);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: var(--menu-h);
    background-color: #8E8A8B;
    color: #194335;
    &.active{
      pointer-events: none;
      background-color: white;
    }
  }

  .line{
    pointer-events: none;
    border-top: 1px solid #fff;
    position: absolute;
    top:30px;
    left: 0;
    width: 100%;
    >i{
      @sz:15px;
      display: block;
      position: absolute;
      width: @sz;
      height: @sz;
      transform: rotate(45deg);
      background-color: white;
      top: -9px;
      &:last-child{
        right: 0;
      }
    }
  }


}
</style>