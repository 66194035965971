/* eslint-disable */
<!-- eslint-disable -->
<template>
  <div id="carte"
      :class="{
          'is-royaume':$layout.isRoyaume
      }"
  >
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1920 1080" enable-background="new 0 0 1920 1080" xml:space="preserve">
          <!--
          <g id="bg">
            <rect fill="#FF00FF" stroke="#FFFFFF" stroke-width="0.4271" stroke-miterlimit="10" width="1920" height="1080"/>
          </g>
          --->
      <g id="ROYAUMES">
        <svg-royaume-container identifiant="zone-non-rattachee">
          <g id="zone-vide">
          <path fill="#C2AE43" d="M933.2,807.2l18.3-10l5-2.3l-11.6-6.7l-6.7-2.7l-6.3-10l-7,0.3l-12-8.3l-8.6,5.3l-8.6,9.3l-2.3-15.6l11-12
          l5.3-6.3l-10.6-3.7l-5.3,4.7l-11,1.7l-7.6-7.6l-7.6-2.7l-1-12.6l8-17.3l-3.3-1l-4,3l-8-1l-7.3,0.7l-5-4l1.7-8l5.3-3.3l-2-5
          l-4.3-3.7l-0.7-9.3l8.6-17.6l-17.6,9.3l-24.6-3.3l-14-2.7l-5.3-4.7l-11-0.4l0,0l-1.1,0.9c0,0-1.1-1.8-2-1.2
          c-0.9,0.5-3.9,4.7-4.4,6.1c-0.4,1.4-1.2,4.1,0.2,5.1c1.4,1.1-2.2,3.9-2.3,2.4c-0.1-1.5-0.1-2.1-1.3-1.1c-1.2,1,1.3,1.8,0.3,2.7
          c-1,1-1.9-0.2-2,1c-0.1,1.2-0.2,4.6-1.2,5.7c-1,1.1,0.8,2.7,0.7,3.8s0.2,2,1.5,1.5c1.3-0.4,2.3,0.2,2,1.1
          c-0.3,0.9,1.2,2.1-0.1,2.5c-1.3,0.4-2.3-1.1-3.4-0.4c-1.1,0.7-0.5,3.6-0.8,5s2,2.3,2.1,4.3c0.1,2,0.3,4.2,1.4,5.1
          c1.1,1-0.9,3.6,0.4,6.1c1.3,2.5,1.9,5.1,2,7.3c0.1,2.2,0.2,5.3,2.1,6.8c1.9,1.5,3.5,3.7,3.5,3.7s-0.8,1.3,0.9,2.2
          c1.6,0.9,1.6,2,3.6,3.1s4.3,3.5,3.7,5.4c-0.5,1.9,2,4.2,1.3,5.7c-0.7,1.5-1.6,4.6,0.1,5.7c1.8,1.1,6.5,5.5,6.6,7.1
          c0.1,1.6,1,3.3-0.7,3.7c0,0,0.4,1.3,1.9,1.9c1.4,0.5-0.3,1.4-1.8,1.1c-1.4-0.3-1.4,1.1-3.7,1.2c-1.4,0.1-1.5,3.4-1.3,6.3
          c-0.2-2.5-0.1-5.3,0.8-6.1l5.8,2.9l2-3.3l5.7-1l2.7-3.3l3.3,2l1-6v-6.7l8,1l8-2l-2,12.3l8-1.3l4,3.7l7,1.3v9.6l-2.3,8.3l7-0.7
          l0.7,7l3.7,9.6l6.3,2.3l4.3-5.7l3.7,1.3l1.7,8.6l2.3,3.7l-4.7,9.6l-6,0.3l-1,8l-4.3,2.3l-2,6.3l-18.3,11.3l-6.4,8.6
          c0.4,0.1,0.9,0.3,1.6,0.4c4.1,1,9.1-0.3,12.4,1.1c3.3,1.4,12.6,3.8,12.6,3.8l4.3,5.3l8.8,4.5c0,0-1.2,3.7,1.9,3.1
          c3.1-0.7,7.1-2.5,11.3-1.9c4.2,0.7,5.5,0.5,8.8-0.9c3.3-1.4,4.7-1.8,6.4-3.7c1.6-2,13.6-8.4,16.2-9.4c1.4-0.5,5.8-2.8,10.1-4.7
          L933.2,807.2z"/>
          <path fill="#C2AE43" d="M1048,603.7c0.2-0.6,0.3-1.1,0.4-1.6C1048.3,602.6,1048.2,603.1,1048,603.7z"/>
          <path fill="#C2AE43" d="M1002.7,673.2l-10,17l-0.7,14.3l-3,5.7v0l24.3,4.8c0,0,0,0,0,0c0.3-1.9,0.6-3.6,0.9-4.4
          c0.9-2.3,0.9-7.1,1.8-8.4c0.9-1.3,3.3-3.7,3-6.6c-0.2-1.7,0.5-5,1.6-7.9L1002.7,673.2z"/>
          <path fill="#C2AE43" d="M958.2,387.7c-0.1-0.1-0.3-0.3-0.4-0.5C957.9,387.3,958.1,387.5,958.2,387.7z"/>
          <path fill="#C2AE43"
              d="M958.3,387.7L958.3,387.7C958.3,387.7,958.3,387.7,958.3,387.7C958.3,387.7,958.3,387.7,958.3,387.7z"/>
          <path fill="#C2AE43" d="M1149.9,307.1c-0.7-1.3-1.2-2.6-1.5-3.7c-0.3-1.1-0.8-2.2-2.1-4.3s-4.4-4.8-4.7-7.2
          c-0.3-2.4-2.2-3.3-2.8-5.6c-0.7-2.3-1.6-10.4-2.7-12.9c-1.1-2.5-2-10.5-1.1-13.4c0.9-2.8,1-6.8-1.6-7.6l0.2-11.1l-1.4-4.2
          c0,0,0.5-10.6-0.4-11.9c-1-1.3-2.1-3.7-2.1-3.7s0.9-2.5-0.4-3.3c-1.3-0.8-1.3-3.7-2.1-5.7s-1.8-3.5-1.2-5
          c0.5-1.5,1.2-4.6-1.5-4.1l-4.8,5.1l-9.6,6h-8l-10.6,5.7l-5.3,4.7l-8.3-0.7l-6.3,5.3l-4.3-0.2l2.9,11.1l-1.3,19.3l-8.3,19.6
          l-9.6,10l-5.3,13.6l-14,17l-20.3,21l-25.9,24.9l-26.8,21.8c0.3,0.3,0.6,0.5,0.9,0.6c1.1,0.4,0.2,2.8,1.2,4.2
          c1,1.3,0.7,3.3-0.1,4.4c-0.8,1.1-1.2,4.1-2.4,5.1s-2.4,2.8-3.4,2.4c-1-0.4,0.8-2.7,2.1-3.7c1.3-1,2.3-2.8,2.1-4.2
          c-0.2-1.3,1.6-2.8,0.7-4.4c-1-1.5-0.5-2.6-2.1-3.6c-1.5-1-1.6-2.5-2.6-4.1c-0.3-0.5-0.8-0.8-1.4-1c0.5,0.2,1.1,0.5,1.4,1
          c1,1.5,1.1,3.1,2.6,4.1l-13.7,15.9l-23.6,24.6l-27.3,50.5l-8.3,27.9l-0.3,32.6l9,67.8l-1.3,11l4.7-10.3l4.3-3.3l4.7,0.7l16-4.7
          l1.3-17l12.6-40.6h26.3l22.6,0.7l11.3-4l18.3,1.7l-1.3,25.6l-7.3,22.6l-4.7,9l15.6,21.9l27,3.8c0.4-1.9,0.4-3.3-0.3-3.1
          c-1.8,0.3-1.5-0.8,0.1-1.8c1.6-1,1.1-3.3,1.8-4.8c0.5-1.1,2.1-7.1,3.4-11.7c-0.2,0.7-0.4,1.4-0.6,2.1l-6.1-0.2l-8-4l4-16.6
          l-1.7-26.9l8.3-10.6l9.3-33.9l4-4.7l-3-21.9l11-46.2l9.3-15.6l8.3,3.3l12.9,0.7c-1.3-1.4,0.2-2.3-1.3-4.6c-1.5-2.4,0-7.1,1.6-9.5
          c1.6-2.4,3.7-8,4.7-10.3c1-2.3,1.9-5.7,3.8-6.5c2-0.8,7.4-2.2,9.4-2.3c2-0.1,1.5-1.2,0.3-1.5c-1.2-0.3-3.6-2.5-3.6-4.4
          c0-1.9-0.2-3.6,2.1-3.8c2.3-0.2,0.4-2.3,2.1-4.1c1.6-1.8,1.6-3.2,2-4.9c0.3-1.8,2.1-5.5-0.1-5.3c-2.2,0.2-0.8-2.8-1.3-4.9
          c-0.5-2.1,3.8-5.9,1.3-7.8c-2.5-1.9-2.8-2-4.7-2c-1.9,0-1.4-1.9-2.3-3.6c-0.9-1.8-1.8-2.1-1.2-4.2c0.5-2.1,3.1-4.7,1.5-6.7
          c-1.5-2-1.4-9.1-4.1-10.5c0,0,1.5-3-0.1-4.5c-1.6-1.5,1.5-5.8,3-8.1c1.4-2.3,3.2-2.3,3.2-2.3l1.5,0.9c0,0,3.5-1.8,5.5-1
          c2,0.8,1.4,5.1,0.5,6.1c-0.9,1,1.1,2.5,2.8,3.3c1.8,0.8,0.1,3.2,0.3,4.7c0.2,1.5,3.4,4.6,3.4,4.6l0.1,5.6c0,0,1.3,0.4,2.2,1.2
          c0.4,0.4,0.8,0.9,0.8,1.4c0.1,1.6,3.2,0.5,3.6,2.8h3.7c0,0-0.1-1.8,1-2.6c1.1-0.9,0-2.3,1.8-2.7c1.8-0.4,0.7-3,1.9-3.2
          c1.2-0.2,1.4-1,1.2-2.4c-0.2-1.4,7.4-23.1,7.4-23.1S1150.5,308.4,1149.9,307.1z M1019,513.9l-2,8.6l-11,1.3l-18.6-7.3l-5.3-1.7
          l-2.3-22.6l17-6l13,1.7l4.7,7.6l8.3,5.3L1019,513.9z"/>
          <path fill="#C2AE43" d="M998.6,775l-22.2-9.7v0l-5.7,3l-1.7,5.3l6.7,9.6l-3.3,6.7l-9.3,6l13.6,0.3l8.7,7.8c0.3-0.7,0.6-1.1,1-1.1
          c1.9,0,1.6-1.3,2.1-3.4c0.2-0.9,0.5-2.3,0.9-3.7c0,0,0,0,0,0c0.6-2,1.3-4,1.9-4.7c1.1-1.2-1-1.2,0.2-2c1.2-0.8,0.9,0.1,2-1.4
          C994.7,786.1,999.2,777.2,998.6,775L998.6,775z"/>
          </g>
        </svg-royaume-container>

    <svg-royaume-container identifiant="mahafale">
      <path id="mahafale" fill="#A49539" d="M870.4,805.9l-1.7-8.6l-3.7-1.3l-4.3,5.7l-6.3-2.3l-3.7-9.6l-0.7-7l-7,0.7l2.3-8.3v-9.6
      l-7-1.3l-4-3.7l-8,1.3l2-12.3l-8,2l-8-1v6.7l-1,6l-3.3-2l-2.7,3.3l-5.7,1l-2,3.3l-5.8-2.9c-1.6,1.4-0.6,9.2-0.1,10.4
      c0.5,1.3,0,2.3,0,3.3c0,1,0.4,7,1.1,8.8s-0.7,17.5,0.1,19.2c0.8,1.6,1.4,3.4,3.5,4.1c2.1,0.7,9.2,9.1,10.3,11
      c1.1,1.9,0.7,4.4-0.5,5c0,0,1,3.7,3.4,5.5c2.4,1.8,1,4.5,1.6,5.6c0.7,1.1,0,2.2,1.4,3.4s1.9,3.6,4.5,3.6s4.3,0.5,6.4,2.3
      c2.1,1.8,3.8,1.5,4.6,2.8s4.2,3.7,2.2,4.3c-1.6,0.5-2.6,0.6-0.5,1.2l6.4-8.6l18.3-11.3l2-6.3l4.3-2.3l1-8l6-0.3l4.7-9.6
      L870.4,805.9z"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="antanosy">
      <path id="antanosy"
      fill="#857A2E"
      d="M976.8,796.2l-13.6-0.3l-6.7-1l-5,2.3l-18.3,10l-9,46.5c3.5-1.6,7.1-2.9,9-2.9
      c4.4,0,4.4,0.2,6-0.4c1.6-0.7,5.8,0.8,7.3,0.5c1.5-0.2,0.9,1.9,3.1,0.8c2.2-1.1,6.2-1.8,7.6-3.4s1.9-2.6,3.7-3.3
      c1.9-0.7,1.8-1.6,3.5-0.7c1.8,1,1,1.2,2.2-0.2c1.2-1.4,0.7-3.4,3.4-3.3c2.7,0.1,3.9-0.5,3.7-2.2c-0.2-1.6-1.1-2,0.1-2.4
      c1.2-0.4-0.1-0.9,1.1-2.5c1.2-1.6,2.3-4.2,2.6-5.7c0.3-1.5,3.8-10.6,5.3-13.6c1.1-2.3,1.6-8,2.6-10.5L976.8,796.2z"/>
    </svg-royaume-container>

        <svg-royaume-container identifiant="bara">
      <polygon id="bara"
      fill="#7C722B"
      points="956.5,794.9 944.9,788.2 938.2,785.6 931.9,775.6 924.9,775.9 912.9,767.6 904.3,772.9
      895.6,782.2 893.3,766.6 904.3,754.6 909.6,748.3 899,744.7 893.6,749.3 882.7,751 875,743.3 867.4,740.7 866.4,728 874.4,710.7
      871,709.7 867,712.7 859.1,711.7 851.7,712.4 846.8,708.4 848.4,700.4 853.7,697.1 851.7,692.1 847.4,688.5 846.8,679.2
      855.4,661.5 882.7,637.6 891.6,619 896.3,608.7 900.6,605.3 905.3,606 921.2,601.3 928.2,606 929.9,618.6 923.9,623.3 921.6,629.9
      916.9,633.3 919.6,643.9 928.6,644.6 939.9,643.6 954.2,649.9 971.1,669.2 975.8,685.8 992.7,690.1 992.1,704.4 989.1,710.1
      985.4,727 981.4,737.3 977.8,744.7 973.5,747.7 974.1,752.3 979.4,752 980.1,758.3 976.4,765.3 970.8,768.3 969.1,773.6
      975.8,783.2 972.5,789.9 963.1,795.9 	"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="antesaka">
      <path id="antesaka"
      fill="#8D8131"
      d="M1002.7,758.1c1.1-2.4,5.7-13.2,5.1-16.1c-0.5-2.8-0.3-5,1.4-6.9s1.6-4.9,1.8-6.2
      c0.1-1.3,2.3-2.5,1.9-4.5c-0.3-1.3,0-5.9,0.5-9.6l-24.3-4.8l-3.7,17l-4,10.3l-3.7,7.3l-4.3,3l0.7,4.7l5.3-0.3l0.7,6.3l-3.7,7
      l22.2,9.7c-0.5-2.2,1.1-5.8,1.8-7.8S1001.6,760.6,1002.7,758.1z"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="tanala">
      <polygon id="tanala"
      fill="#746B28"
      points="992.7,690.1 975.8,685.8 971.1,669.2 978.8,634.3 993.7,612.3 1000.4,599.3
      1016,621.3 1002.7,673.2 	"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="antemoro">
      <path id="antemoro"
      fill="#A49539"
      d="M1016,621.3l-13.3,51.9l17.9,14.3c0.7-1.9,1.5-3.6,2.3-4.5c2-2.3,1.8-5.8,3.4-8
       c1.6-2.2,5-11.4,5.4-13.9s2.3-5,2.6-7.7c0.3-2.6,4.2-11.7,4.7-13.7c0.5-2,0-4.2,1.4-6.8c0.8-1.5,2.1-5.2,2.7-7.9L1016,621.3z"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="betsileo">
      <polygon id="betsileo" fill="#9D8F37" points="1000.4,599.3 993.7,612.3 978.8,634.3 971.1,669.2 954.2,649.9 939.9,643.6
      928.6,644.6 919.6,643.9 916.9,633.3 921.6,629.9 923.9,623.3 929.9,618.6 928.2,606 921.2,601.3 922.6,584.4 935.2,543.8
      961.5,543.8 984.1,544.5 995.4,540.5 1013.7,542.1 1012.4,567.8 1005,590.4 	"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="betsimisaraka">
      <g id="betsimisaraka">
      <polygon fill="#6B6324" points="1051.1,592.5 1051.1,592.5 1051.1,592.5 		"/>
      <path fill="#6B6324" d="M1078.1,513.6c1.6-2,2.1-4.5,3-7c0.9-2.5,2.8-10.5,4.6-14.1s2.8-6,3.5-8.1c0.7-2.1,2.1-1.3,1.8-4.7
      c-0.3-3.4,1.8-8.5,1.6-10.4c0,0,3-1.6,2.5-3.3c-0.4-1.6-1.3-1.5-1.3-1.5s0.3-3,1-4.3c0.7-1.3-0.4-3.5,0.3-5.4
      c0.8-1.9,6-12.2,4.5-14.2s-2.6-4.1-2.5-6.7c0.1-2.6,0.7-3.1-1.1-4.6c-0.1-0.1-0.2-0.2-0.3-0.2l-12.9-0.7l-8.3-3.3l-9.3,15.6
      l-11,46.2l3,21.9l-4,4.7l-9.3,33.9l-8.3,10.6l1.7,26.9l-4,16.6l8,4l6.1,0.2c0.7-2.6,1.4-5,1.8-6.5c0.9-3.1,1.1-4.3,1.9-6.9
      c-0.4,0-0.7,0-1,0c0.3,0,0.7,0,1,0c0.2-0.5,0.3-1,0.5-1.7c1.3-3.9,7.7-18.2,8.1-20c0.4-1.9,0.8-5.7,3-7.4c2.2-1.8,0.8-3.2,1.3-8.7
      c0.5-5.5,1.5-8.2,1.5-8.2s3.2-6.9,4.3-9.6c1.1-2.7,3.1-4.7,2.8-8C1072.4,525.6,1076.5,515.6,1078.1,513.6z"/>
      </g>
    </svg-royaume-container>

    <svg-royaume-container identifiant="imerina">
      <polygon id="imerina" fill="#A6963A" points="979.8,492.3 982.1,514.9 987.4,516.5 1006,523.9 1017,522.5 1019,513.9 1022.7,500.9
      1014.4,495.6 1009.7,487.9 996.7,486.3 	"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="sakalava-menabe">
			<path id="sakalava-menabe"
            fill="#625A20"
            d="M934.4,414l-30.8-25.4l-21.5-34.2c-0.4-0.7-1.1-1.5-2.1-1.3c-1.6,0.4-1.6,3-3.2,2.4
				c-1.5-0.5,0.5-3.7,0.1-4.4c-0.4-0.7-1.6-1.8-1.2-3.2s0.4-3.4-0.9-3.4s-5.4,3.4-6.4,4.4c-1,1-2.8,5.7-5.3,6.6
				c-2.4,0.9-4.2,2.4-4.2,2.4l0.2,1.4l-3-0.2l-2,2.2l-3.2-0.3l-1.3-1.3l-2.2,0.5l-11.6-1.1l-1.3-0.8l-1.6,1l-0.3,4.8l-1.9,1.6
				l0.1,2.2l4.1,6.4l-0.4,3.3l-1.6,1.6l-0.8,2.1c0,0,0.8,2.5,0.9,3.5c0.1,1-0.2,3-0.2,3s-1.6,0.2-2.5,1.6c-0.9,1.4-2.6,4.6-2.6,5.9
				h-1.3l-1.6,5.3l-1.8,0.9c0,0-1.3,4.8-4.4,6.7c0,0,1,2.1-1.4,2c0,0,0,3.5-1,3.7c-1,0.2-1.2,0.9-1.3,1.6c-0.1,0.8-3.7,5.4-4.5,6.5
				c-0.8,1.1-3.7,7.1-4.1,11.1c-0.3,3.9,2.8,7.7,3.1,8.5s2.7,3.2,2.7,3.2l-0.4,5.4l-1.3,3.4c0,0-0.3,8,0.5,9.9
				c0.9,1.9,2.7,9.4,2,11.3c-0.8,1.9-1.4,7.2,1.1,9c2.5,1.8,4.4,5.4,4.4,6.8c0,1.4,1.3,1.6,2.2,3.5c0.9,1.9,3.2,9.3,3.2,9.3
				s-1.3,6.4-0.9,7.7s-1.4,2-0.4,4.6c1,2.6,2,7.4,6,11.6l0,0.1l0.9,2.5l1.6,1.5l3.7,6.6l-0.1,3.4c0,0,1.6,0,1.1,1.1
				c-0.5,1.1-0.4,2.8-2.3,3.5c-1.9,0.7-0.2,5.6-1.6,6.8c-1.4,1.2-1.5,4.2-0.1,5.1c1.4,1,1.2,1.1,2.3,2.6s1.6,2.4,1.4,3.8
				c-0.2,1.4,0.8,2.6-1,2.6s-0.3,2.3-2,3.5c-1.6,1.2,0,1.8-1.5,3c-1.5,1.2-5.5,8.3-5.5,8.3s-2.7,4.8-3.1,7.2
				c-0.3,2.4-4.6,3.6-4.6,3.6s-0.2,2.8-1.5,2.8c-1.3,0-2.1,3.9-1.8,5.4c0.3,1.4-2.8,8.4-5,8.5c-2.2,0.1-3,2-3.7,3.5
				c-0.8,1.5-2.1,4.2-2.6,6.8c-0.5,2.6,0.2,4.6-1.3,5.3s0.5,3-1.3,6.4c-1.9,3.4-2.4,5.9-4.1,6.6c-1.6,0.7-6,0.2-8.1,0.4
				c-2.1,0.2-2.8,2.4-4.2,3c-1.3,0.5-1.6,0.4-2.4,3.3c-0.8,2.8-1.2,9.1-0.3,11.1s1.3,4.5,1.3,4.5l11,0.4l5.3,4.7l14,2.7l24.6,3.3
				l17.6-9.3l14.3-12l13-12l9-18.6l1.3-11l-9-67.8l0.3-32.6l8.3-27.9l27.3-50.5L934.4,414 M831,525c-0.1,0.3-0.7,0.9-1.5,1.5
				C830.3,525.9,830.9,525.4,831,525c0-0.1,0.1-0.2,0.1-0.2C831.1,524.9,831,524.9,831,525z"/>
    </svg-royaume-container>

    <svg-royaume-container identifiant="sakalava-boina">
        <g id="sakalava-boina" >
        <path
        fill="#B1A03D"
        d="M1068.6,259.8l1.3-19.3l-3-11.3l-14.3-0.3l-9.3,0.3l-7.4-2.4l0,0c-1.3,0.4-1.3,1.8-3.6,1.1
        c-2.3-0.7-3.7-3.5-3.7-3.5s0-3.2-0.8-4.1c-0.8-0.9-0.8-3.6-0.8-3.6s-2.5-0.1-3-1.5c-0.4-1.4-1-3.4-3.6-3.1c0,0,1.8,1,1,1.8
        c-0.8,0.8,0,1.9-1.3,1.6c-1.3-0.2-1.3-2-1.2-2.7c0.1-0.8-2-1-1.6,0.7c0.3,1.6,0.1,2.5-1.5,2.7s-2.4,1-2.3,2c0.1,1,0.2,3.4,1.2,3.8
        c1,0.4,1,1.4-0.2,1.4c-1.2,0-1,1.6-1.3,2.3c-0.3,0.7-0.7,1.4,0.3,2.3v2.6l1.6,3c0,0,2.2,0.7,3.7,1.6c1.5,1,1.6,1.6,0.8,2
        c-0.9,0.3-0.1,1.3-1.6,1.2c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
        c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2
        c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.8,1,3.9,3.3,4.7,3.7c0.8,0.4,1.1,2,0.1,2.4c-1,0.4,1.1,1.5,0,2.2
        c-1.1,0.7-2.2,3.1-1.4,4.1c0.8,1,1,2,0.1,2.3c-0.9,0.3-0.9,1.3-1,2.3c-0.1,1-1.4,0.4-1.3-1.4c0.1-1.9,1-6.7,2-7.6
        c1-0.9-0.8-2.1-2-2.2c-1.2-0.1-1-1.9-2.6-1.8c-1.6,0.1-1,1.1,0.1,2c1.1,0.9,2.3,1.9,1.8,2.7s-0.3,3.9-1.4,4.2s0-1.3-1.3-1.2
        c-1.3,0.1-0.1,1.9-1.8,1.4s-3.6-2.3-3.6-2.3l-1.2,1c0,0,0.3,3.6-1,3.9c-1.3,0.3-2.4,2.7-2.4,2.7s-0.2,2.7-1.2,3.2s2.6,5.8,2.6,5.8
        s-1.3,1.3-1.2,2.5c0.1,1.2,1.4,2.2,2.5,1.4c1.1-0.8,1.8-2.3,2.4-1.9c0.7,0.4,0.4,2.2,2.1,2.4c1.6,0.2,1-1.5,2.4-1.3
        c1.4,0.2,2.1-0.2,3,1.1c0.9,1.3,3.1,2.1,3.1,2.1s-0.7,2.4-0.1,3.5s-1.1,4.6-1.1,4.6s0.1-4.9-0.3-6c-0.4-1.1-1.4-2.3-1.4-2.3
        l-1.9,0.7c0,0,1.4,2.7,0.1,3.6c-1.3,0.9-0.1-2-1.5-2.5s-3.2-1.6-4.2-3s-2.7,0.1-3.4,1.3c-0.7,1.2,0,1.9-1.8,2.4s-0.9,2.4-2.1,3.3
        c-1.2,0.9-1.9,2.8-2,3.8c-0.1,1,0.1,1.8-1.1,1.5c-1.2-0.2-0.3,2.3-1.3,3.2c-1,0.9-1.4,2.4-1.5,3.5s-0.2,2.4-1.4,2
        s-2.2,2.6-2.5,3.8c-0.3,1.2-2,3.1-3.5,2.4s-0.9-8.1,0.2-9.2s3.3-4.7,3.6-5.6s0.9-1,0-1.6c-0.9-0.7,0-4.9,0-4.9s-1.5-3-2.5-2.6
        c-1,0.3-3.4,4.7-4.6,5.1c-1.2,0.4-2,1.1-2,1.1s-3.1,3.8-2.4,4.7c0.7,0.9,2,1.3,2.7,0.8c0.8-0.5,0-1.8,1.4-1.3
        c1.4,0.4,1.8,1.8,0.7,2.4s-0.9,1.3-2.5,0.9c-1.6-0.4-3.4,1.5-3.4,1.5v1.8l-2.2,0.8l-0.3,3l-3.7,3.8l-1.6-0.3c0,0-3,3.8-3.2,4.8
        c-0.2,1,0.5,2.7,0.5,2.7l1.4,0.8l-0.3,1.1l2.1-0.2l-0.3,1.8l2.2,0.1l-0.1,3.1l2.6,2.1c0,0-0.9,1.1,0.2,1.4c1.1,0.3,2.1,1.9,0,1.6
        c-2.1-0.2-2.3-0.8-4.1,0s-2,1.8-4.6,2s-2.4,1.3-3.1,2.6c-0.7,1.3-1.6,1.2-1.5-0.7c0.1-1.9,3.4-7.8,3.4-7.8h1.4
        c0,0,2.1-3.5,1.1-3.8c-1-0.3-5.3-2-5.1-3.1c0.1-1.1,0.4-3.4-0.8-3.6s-4.6,2.2-6,3.5c-1.4,1.3-16.4,14.1-16.4,14.1h-2l-5.5,4.5
        c0,0-2.4,3.8-2,5c0.4,1.2,1.6,4.5,0.5,5c-1.1,0.5-2.5,2.1-2.5,2.1l1.3,1.2l3.6,0.8c0,0,3.4,4.7,2.8,5.1c-0.5,0.4-3-3.3-3.9-3
        c-1,0.3,0.1,4.5,5.8,4.2c0,0,6.2,3.3,6.5,5.1s2.3,2.8,0.8,4.7c-1.5,1.9-3.6,2.7-3.1,4.8c0.5,2.1,1.1,3.3,0.4,4.4
        c-0.7,1.1,2.4,2,1.4,3.3c-1,1.3-3.6,0.8-2.7,1.8c0.9,1,2.5,0.4,3.4-0.2c0.9-0.7,4.9,1.6,5.4,3.2c0.4,1.5-0.4,7.2,1.6,8.1
        c2.1,0.9,2.8,1.4,2.8,2.6c0,0.9,0.8,2.2,1.6,3l26.8-21.8L1011,341l20.3-21l14-17l5.3-13.6l9.6-10L1068.6,259.8z"/>
          <path
              fill="#B1A03D"
              d="M934.4,414l9.1-9.5l13.7-15.9c-1.5-1-1.6-2.5-2.6-4.1c-1-1.5-3.6-0.9-3.6-2.5c0-1.6,0.5-7.2-0.8-8.3
          c-1.3-1.1-2.3-1.4-4.6-1.1c-2.3,0.3-4.9,0.2-4.2-1.8c0.8-2,0.9-2.4,2.3-2.5s-2.3-1.5-0.9-2.8s0-2.6-0.4-4.2
          c-0.4-1.5,2.1-4.2,3.2-4.9c1.1-0.8,1.4-3.5-3.2-4.2c-4.6-0.7-5.3-0.3-8.1-2.3c-2.8-2-4.1-2.2-4.7-4.9c-0.7-2.7-0.5-4.7-2.8-5.7
          c0,0,0.2-1.2,1.2-1.3c1-0.1-0.7-3.3-0.1-4.8c0.5-1.5-4.4-0.9-7-0.2c-2.6,0.7-6,5-5.4,5.5c0.7,0.4,3.2-1.1,3.5-0.4
          c0.3,0.7-2.2,1.4-1.3,2.3c0.9,0.9,1.9,1.9-0.1,1.6c-2-0.2-0.8-1.5-2.8-1.4c-2.1,0.1-2.2,0.2-2.3-1.2c-0.1-1.4-1.1-3-4.3-2.5
          s-1.9,2.4-3.4,2.2c-1.5-0.2-6.2-1.2-7-2.1c-0.8-0.9-1.5,2.7-3.4,3.4l1.4,1.9l-0.3,4.4l-1.3,1.1l-0.4,4.9l-1.2-1.6l-1.2-0.1
          c0,0,0.9-4.9-0.5-4.7c-1.4,0.2-9.7,1.8-9.7,1.8s-0.1,2.1,1.1,2.3c1.2,0.2,1.3,1.1,0.8,1.8c-0.4,0.5-0.9,1.5-0.8,2.4l21.5,34.2
          L934.4,414z"/>
        </g>
    </svg-royaume-container>
    <svg-royaume-container identifiant="antakarana">
        <path id="antakarana" fill="#58521C" d="M1123.4,199.3c-1-1.5-2.8-3.5-2.3-6c0.5-2.5,0-7.9,0-7.9s-1.2-4.7-3.3-4.6
        c-2.1,0.1,0.8-1.5-0.4-2.3s-2.3-0.8-2.7-1.8c-0.4-1-0.9-3.7-2.3-4.8c-1.4-1.1-2.3-2.2-3-0.5c-0.7,1.6-0.8,2.5-2.2,2
        c-1.4-0.5,0.5-2.8,0-3.8c-0.5-1-2.3-4.7-4.1-4.3c-1.8,0.4-2.3-1.9-1.5-3.1s-1.3-3.1-0.1-4.3c1.2-1.2,0.7,2.2,1.5,0.9
        s-0.1-5.3-1.6-6.7c-1.5-1.4-1.1-3.9-3.2-3.7c-2.1,0.2-1.9,2-2.8,1.5s0-2.8-0.9-4.3c-0.9-1.4-0.9-3.4-2.6-3c-1.8,0.4,1.1,3.2-1.2,3
        s-1.2-2.5-2.6-2.1c-1.4,0.4-1.1,2.2-3.2,2.1c-2.1-0.1-1.6-0.8-1.5-1.4c0.1-0.7,0.7-1.5,1.3-1c0.7,0.5,1.5,0.7,2-0.3
        c0.4-1-0.7-0.9-1.6-1.5s0.2-1.3-0.1-2c-0.3-0.7-0.1-1,0.7-2c0.8-1,1.1,2.4,2.4,2.1c1.3-0.3,0.4-2.2,1.9-1.2c1.4,1,1.1,2.8,2.3,2.2
        s-0.5-3.1-0.8-3.9c-0.2-0.9,1-2.1,0.3-2.8c-0.7-0.8-3.1-0.1-2.8-1.1c0.2-1-0.1-4.5-1.2-5.2l-2.2-1.5c0,0-2.2,1.8-2.4,2.5
        c-0.2,0.8,0,2,0,2s-1-0.5-1.5,0.1c-0.5,0.7-0.2,1.2-0.2,1.2s1.8,0,1.5,0.5c-0.2,0.5,0,0.9,0.9,1.5c0.9,0.7-0.8,2-1.4,0.8
        s-1.6-2.7-2.4-2.6c-0.8,0.1,0,0.9-1.1,1.1c-1.1,0.2-1.6,0.4-1.8,1.3c-0.1,0.9,1,0.3,1.4,0.9s0.8-0.8,1.4,0s-0.2,1.1,1.3,0.8
        c1.5-0.3,1.6,2.1,0.1,1.9c-1.5-0.2-1.8,1.8-1.1,2.7c0.7,1,0.8,1.8-0.1,1.6c-0.9-0.1-0.2,1.2-1.1,0.9c-0.9-0.3-0.4-0.9-1.4-0.8
        c-1,0.1-0.1,2.7-1.3,3c-1.2,0.2-0.3-1-1.1-1c-0.8,0,0,1.3-0.9,1.2c-0.9-0.1-1,0-0.9,1.3c0,0-0.5-0.4-1.5,0c-1,0.4-1.8,1.6-1.8,1.6
        s0.7,1.9,0.3,2.4c-0.3,0.5-1,3.2-1.4,4.3c-0.4,1.1-1.9,1-2.6,0.1s-1-3.4-2.2-3.5c-1.2-0.1-2.8,0.1-4.3-0.7c0,0-1.3,0.4-2,1.3
        c-0.7,0.9,0.7,2.1,1.6,1.3c1-0.8,1.3,0,1.1,0.9c-0.2,0.9-0.8,1.2,1,1.9c1.8,0.7,1.9,2.1,1.4,2.6c-0.4,0.5,0.5,1.1,0.5,1.1
        s0.9-1.3,1.8-0.9c0.9,0.4,0.4,1.3-0.2,1.9c-0.7,0.5,0.1,1.2,0.1,2c0,0.8-1.8,1.5-0.2,2.1c1.5,0.5,1.8,0.7,1.5,1.6
        c-0.2,1,0.2,4.2,0.2,4.2s1.9,1.1,2.3,2.5s-0.3,4.7-0.5,5.8c-0.2,1.1-0.8,0.3-1.3,1.5s0.7,1.9-0.5,2.2c-1.2,0.3-1,0.7-1.3,1.5
        c-0.3,0.9-0.2,1.2-1.1,1.6c-0.9,0.4,0.2,2.2-0.7,2.6c-0.9,0.4-1.3,1-1.3,3c0,2-1.2,4.3,0.3,5.4c1.5,1.1,0.8,1.9,0.8,1.9
        s-0.9-0.3-1.6,0.5c-0.8,0.9-1.2,3.1-1.2,3.1s1.6,0.8,0.7,1.3c-1,0.5-1.2,0.2-2,1.3s-0.5,2-1.5,1.6c-1-0.3-0.8,0.9-2.7,1
        c-2,0.1-5.1,0.8-6,0.1c-0.9-0.7-3-3-3.3-3.8c-0.3-0.9-1.5-0.5-1.4,0.8c0.1,1.3-0.1,2.4,1,3.3c1.1,0.9,0.1,1.8,1.1,3
        c1,1.2,2.1,0.7,1,1.2c-1.1,0.5-2.3-0.9-2.3-0.9s0.1,0.9-1.2,0.7c-1.3-0.2,0,1.1-1.5,0.9c-1.5-0.2-1.3,1.2-2.5,0.9
        c-1.2-0.3-1.4-1.2-2.2-0.5c-0.8,0.7-1.3,1.4-0.5,2.1c0.8,0.7,1.1,2,0.3,2.6c-0.8,0.7-1.5,2.3-1.1,3.2s-0.1,2.4,0.3,3.2
        s0.7,2-0.7,2.4l7.4,2.4l9.3-0.3l18.6,0.7l6.3-5.3l8.3,0.7l5.3-4.7l10.6-5.7h8l9.6-6l4.8-5.1
        C1124.2,203.5,1124.3,200.8,1123.4,199.3z"/>
    </svg-royaume-container>

</g>
      <g id="FIXE">
	<g>
		<g>
			<g>
				<path fill="#C2AE43" d="M1072.8,143.2c1.6-0.3,1.1-2.3,0.3-2.7C1072.1,139.9,1071.1,143.5,1072.8,143.2z"/>
        <path fill="#C2AE43" d="M1056.3,151.4c1.3-0.1,0.5-2-0.2-2.2C1055.5,149,1055,151.5,1056.3,151.4z"/>
        <path fill="#C2AE43" d="M1054.8,154.4c1.1,0,1.5-1.3,0.2-1.8C1053.9,152.2,1053.7,154.4,1054.8,154.4z"/>
        <path fill="#C2AE43" d="M1055,171.3c0.7,0.1,0.8-2.3,0.8-2.3C1054.8,169,1054.4,171.2,1055,171.3z"/>
        <path fill="#C2AE43" d="M1052.3,174c-0.7-0.7-0.1,2.5-1,3.1C1050.4,177.6,1054.8,176.4,1052.3,174z"/>
        <path fill="#C2AE43"
              d="M1050.3,180c1.3-0.8,0.8-2.8-0.5-2.6c-1.3,0.2-0.4-0.7-1.6-0.5C1047.3,176.9,1049,180.8,1050.3,180z"/>
        <path fill="#C2AE43" d="M1051.5,180.5c-0.4,0.7,1,1.3,1.3,0.4C1053.2,180.1,1051.5,180.5,1051.5,180.5z"/>
        <path fill="#C2AE43" d="M1048.4,181.3c-0.8,0.1,0.5,1.5,1.3,0.9C1050.4,181.5,1049,181.2,1048.4,181.3z"/>
        <path fill="#C2AE43" d="M1045.3,202.7c1.5-0.1,1.1-2.7-0.4-2.7C1043.9,199.9,1043.8,202.8,1045.3,202.7z"/>
        <path fill="#C2AE43" d="M1031.5,205.4c2,0,3.6,0.3,4.5,1.1c0.9,0.8,2.8-0.1,2-1.2c-0.9-1.1-2.1-2.1-1.2-2.8
					c0.9-0.8-0.1-1.4-0.2-2.4s1-4.4-1.9-4.7c-1.8-0.2-1.2,1.6-1,2.7c0.2,1.1-2,1.2-3.2,0.5c-1.2-0.7,0.1,2-0.9,2.8
					C1028.6,202.3,1029.5,205.4,1031.5,205.4z"/>
        <path fill="#C2AE43" d="M1038.4,211c1.6-0.4,0.5-2.7-0.8-2.6C1036.5,208.4,1036.7,211.4,1038.4,211z"/>
        <path fill="#C2AE43" d="M1010.1,241.7c1.4-0.7-0.2-3.5-1.3-3.5C1007.9,238.2,1008.7,242.4,1010.1,241.7z"/>
        <path fill="#C2AE43" d="M1008.1,236.5c0.9-0.5,1-2.3-0.7-2C1006.2,234.8,1007.2,237,1008.1,236.5z"/>
        <path fill="#C2AE43" d="M1006.7,245.8c1.2-0.5,0.1-2.1-0.9-1.9C1004.7,244.2,1005.5,246.3,1006.7,245.8z"/>
        <path fill="#C2AE43" d="M1009.3,247.1c-0.9,0.3,0.1,2.2,1.1,1.5C1011.3,248,1010.4,246.8,1009.3,247.1z"/>
        <path fill="#C2AE43" d="M1000.2,259c1,0,1.3-1.8,0.3-2.1C999.4,256.6,999.2,259,1000.2,259z"/>
        <path fill="#C2AE43" d="M999.3,270.4c1.4-0.4,0.5-2.2-0.2-2.5c-0.8-0.3,0.3-1.5-0.8-1.6c-1-0.1,0.2,1.6-0.8,2.3
					C996.6,269.2,997.9,270.9,999.3,270.4z"/>
        <path fill="#C2AE43" d="M944.4,351.6c0,0-0.7-0.9-3.3-1.9c-2.6-1-5.1-1.6-5.1-1.6s0.9,2.7,3.8,2.7
					C942.8,350.8,944.4,351.6,944.4,351.6z"/>
        <path fill="#C2AE43" d="M806,369.8c1.4,0.1,1.5-3-0.1-3C804.8,366.8,804.6,369.6,806,369.8z"/>
        <path fill="#C2AE43" d="M805.8,478.6c0,0-1,2.8,0.7,2.2C808.1,480.1,807.2,478.3,805.8,478.6z"/>
        <path fill="#C2AE43" d="M798.1,484.1c1.4-0.3,0.7-1.5-0.2-1.3C796.8,483,796.7,484.4,798.1,484.1z"/>
        <path fill="#C2AE43" d="M802.3,486.7c1.4-0.1-1.1,1.6,0,1.5c1.1-0.1,3.1-2.1,1.5-2.5c-1.5-0.4-1.2-0.9-2.3-0.7
					C800.2,485.3,800.9,486.8,802.3,486.7z"/>
        <path fill="#C2AE43" d="M805.7,487.8c-0.8,0-0.5,2.3,0.8,1.6C807.8,488.8,806.4,487.8,805.7,487.8z"/>
        <path fill="#C2AE43" d="M832.3,544c-1.3-0.4-3,2.7-1.8,2.7C831.8,546.7,833.3,544.3,832.3,544z"/>
        <path fill="#C2AE43" d="M832.2,562c0.9-0.2,1.1-2.6-0.4-2.3C830.8,560,831.3,562.3,832.2,562z"/>
        <path fill="#C2AE43" d="M798,611.7c-1.1,0.1-1,1.6,0.1,1.3C799.2,612.7,799.9,611.5,798,611.7z"/>
        <path fill="#C2AE43" d="M793.4,626.2c1.1,0,1.8-1.1,0.7-1.4C793.2,624.5,792.3,626.2,793.4,626.2z"/>
        <path fill="#C2AE43" d="M787.5,767.7c-0.9,0.4,0.4,2.6,1.2,1.9C789.5,768.8,788.7,767.1,787.5,767.7z"/>
        <path fill="#C2AE43" d="M819.6,854.3c-1.4,0.2-1.1,1.8-0.1,1.4C820.5,855.4,820.6,854.2,819.6,854.3z"/>
        <path fill="#C2AE43" d="M1124.9,388.5c-1.2-0.8-4.8,9.2-5.7,9.6c-0.9,0.4-2.1,3.9-1.9,5.3c0.2,1.3-0.5-0.2-1.4,2.1
					c-0.9,2.3-2.7,5-1.2,5.3c1.5,0.2,2.6-4.1,3.5-4.4c0.9-0.3-0.1-2.2,1.3-3.3c1.4-1.1,2.7-5.5,3.2-7.3
					C1123.1,393.8,1126.4,389.5,1124.9,388.5z"/>
        <path fill="#C2AE43" d="M1132.3,348.6c-1.2,0-1.2,1.9-0.2,2C1133.1,350.7,1134,348.6,1132.3,348.6z"/>
        <path fill="#C2AE43" d="M1116.5,173.7c1-0.1,0.5-2.6-0.2-2.2C1115.5,172,1115.4,173.9,1116.5,173.7z"/>
        <path fill="#C2AE43" d="M1113.8,171.3c0.7,0.3-0.3-1.6-1.6-1.6C1110.9,169.7,1113,171,1113.8,171.3z"/>
        <path fill="#C2AE43" d="M1106.7,164.9c-1.4,0.2,0.5,2.5,1.1,3.2C1108.5,168.8,1108.1,164.7,1106.7,164.9z"/>
        <path fill="#C2AE43" d="M1103.1,163.6c1.4,0,1.7-2.4,0.4-2.5C1102.6,160.9,1101.7,163.6,1103.1,163.6z"/>
        <polygon fill="#C2AE43" points="989.5,795.7 989.5,795.7 989.5,795.7 				"/>
			</g>
		</g>
    <g>
			<path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M1123.4,199.3c-1-1.5-2.8-3.5-2.3-6c0.5-2.5,0-7.9,0-7.9
				s-1.2-4.7-3.3-4.6c-2.1,0.1,0.8-1.5-0.4-2.3s-2.3-0.8-2.7-1.8c-0.4-1-0.9-3.7-2.3-4.8c-1.4-1.1-2.3-2.2-3-0.5
				c-0.7,1.6-0.8,2.5-2.2,2c-1.4-0.5,0.5-2.8,0-3.8c-0.5-1-2.3-4.7-4.1-4.3c-1.8,0.4-2.3-1.9-1.5-3.1s-1.3-3.1-0.1-4.3
				c1.2-1.2,0.7,2.2,1.5,0.9s-0.1-5.3-1.6-6.7c-1.5-1.4-1.1-3.9-3.2-3.7c-2.1,0.2-1.9,2-2.8,1.5s0-2.8-0.9-4.3
				c-0.9-1.4-0.9-3.4-2.6-3c-1.8,0.4,1.1,3.2-1.2,3s-1.2-2.5-2.6-2.1c-1.4,0.4-1.1,2.2-3.2,2.1c-2.1-0.1-1.6-0.8-1.5-1.4
				c0.1-0.7,0.7-1.5,1.3-1c0.7,0.5,1.5,0.7,2-0.3c0.4-1-0.7-0.9-1.6-1.5s0.2-1.3-0.1-2c-0.3-0.7-0.1-1,0.7-2c0.8-1,1.1,2.4,2.4,2.1
				c1.3-0.3,0.4-2.2,1.9-1.2c1.4,1,1.1,2.8,2.3,2.2s-0.5-3.1-0.8-3.9c-0.2-0.9,1-2.1,0.3-2.8c-0.7-0.8-3.1-0.1-2.8-1.1
				c0.2-1-0.1-4.5-1.2-5.2l-2.2-1.5c0,0-2.2,1.8-2.4,2.5c-0.2,0.8,0,2,0,2s-1-0.5-1.5,0.1c-0.5,0.7-0.2,1.2-0.2,1.2s1.8,0,1.5,0.5
				c-0.2,0.5,0,0.9,0.9,1.5c0.9,0.7-0.8,2-1.4,0.8s-1.6-2.7-2.4-2.6c-0.8,0.1,0,0.9-1.1,1.1c-1.1,0.2-1.6,0.4-1.8,1.3
				c-0.1,0.9,1,0.3,1.4,0.9s0.8-0.8,1.4,0s-0.2,1.1,1.3,0.8c1.5-0.3,1.6,2.1,0.1,1.9c-1.5-0.2-1.8,1.8-1.1,2.7
				c0.7,1,0.8,1.8-0.1,1.6c-0.9-0.1-0.2,1.2-1.1,0.9c-0.9-0.3-0.4-0.9-1.4-0.8c-1,0.1-0.1,2.7-1.3,3c-1.2,0.2-0.3-1-1.1-1
				c-0.8,0,0,1.3-0.9,1.2c-0.9-0.1-1,0-0.9,1.3c0,0-0.5-0.4-1.5,0c-1,0.4-1.8,1.6-1.8,1.6s0.7,1.9,0.3,2.4c-0.3,0.5-1,3.2-1.4,4.3
				c-0.4,1.1-1.9,1-2.6,0.1s-1-3.4-2.2-3.5c-1.2-0.1-2.8,0.1-4.3-0.7c0,0-1.3,0.4-2,1.3c-0.7,0.9,0.7,2.1,1.6,1.3
				c1-0.8,1.3,0,1.1,0.9c-0.2,0.9-0.8,1.2,1,1.9c1.8,0.7,1.9,2.1,1.4,2.6c-0.4,0.5,0.5,1.1,0.5,1.1s0.9-1.3,1.8-0.9
				c0.9,0.4,0.4,1.3-0.2,1.9c-0.7,0.5,0.1,1.2,0.1,2c0,0.8-1.8,1.5-0.2,2.1c1.5,0.5,1.8,0.7,1.5,1.6c-0.2,1,0.2,4.2,0.2,4.2
				s1.9,1.1,2.3,2.5s-0.3,4.7-0.5,5.8c-0.2,1.1-0.8,0.3-1.3,1.5s0.7,1.9-0.5,2.2c-1.2,0.3-1,0.7-1.3,1.5c-0.3,0.9-0.2,1.2-1.1,1.6
				c-0.9,0.4,0.2,2.2-0.7,2.6c-0.9,0.4-1.3,1-1.3,3c0,2-1.2,4.3,0.3,5.4c1.5,1.1,0.8,1.9,0.8,1.9s-0.9-0.3-1.6,0.5
				c-0.8,0.9-1.2,3.1-1.2,3.1s1.6,0.8,0.7,1.3c-1,0.5-1.2,0.2-2,1.3s-0.5,2-1.5,1.6c-1-0.3-0.8,0.9-2.7,1c-2,0.1-5.1,0.8-6,0.1
				c-0.9-0.7-3-3-3.3-3.8c-0.3-0.9-1.5-0.5-1.4,0.8c0.1,1.3-0.1,2.4,1,3.3c1.1,0.9,0.1,1.8,1.1,3c1,1.2,2.1,0.7,1,1.2
				c-1.1,0.5-2.3-0.9-2.3-0.9s0.1,0.9-1.2,0.7c-1.3-0.2,0,1.1-1.5,0.9c-1.5-0.2-1.3,1.2-2.5,0.9c-1.2-0.3-1.4-1.2-2.2-0.5
				c-0.8,0.7-1.3,1.4-0.5,2.1c0.8,0.7,1.1,2,0.3,2.6c-0.8,0.7-1.5,2.3-1.1,3.2s-0.1,2.4,0.3,3.2s0.7,2-0.7,2.4l7.4,2.4l9.3-0.3
				l18.6,0.7l6.3-5.3l8.3,0.7l5.3-4.7l10.6-5.7h8l9.6-6l4.8-5.1C1124.2,203.5,1124.3,200.8,1123.4,199.3z"/>
      <g>
				<path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M1068.6,259.8l1.3-19.3l-3-11.3l-14.3-0.3l-9.3,0.3l-7.4-2.4l0,0
					c-1.3,0.4-1.3,1.8-3.6,1.1c-2.3-0.7-3.7-3.5-3.7-3.5s0-3.2-0.8-4.1c-0.8-0.9-0.8-3.6-0.8-3.6s-2.5-0.1-3-1.5
					c-0.4-1.4-1-3.4-3.6-3.1c0,0,1.8,1,1,1.8c-0.8,0.8,0,1.9-1.3,1.6c-1.3-0.2-1.3-2-1.2-2.7c0.1-0.8-2-1-1.6,0.7
					c0.3,1.6,0.1,2.5-1.5,2.7s-2.4,1-2.3,2c0.1,1,0.2,3.4,1.2,3.8c1,0.4,1,1.4-0.2,1.4c-1.2,0-1,1.6-1.3,2.3
					c-0.3,0.7-0.7,1.4,0.3,2.3v2.6l1.6,3c0,0,2.2,0.7,3.7,1.6c1.5,1,1.6,1.6,0.8,2c-0.9,0.3-0.1,1.3-1.6,1.2c-0.2,0-0.4,0-0.5,0
					c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0.1-0.1,0.1
					c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3
					c0.8,1,3.9,3.3,4.7,3.7c0.8,0.4,1.1,2,0.1,2.4c-1,0.4,1.1,1.5,0,2.2c-1.1,0.7-2.2,3.1-1.4,4.1c0.8,1,1,2,0.1,2.3
					c-0.9,0.3-0.9,1.3-1,2.3c-0.1,1-1.4,0.4-1.3-1.4c0.1-1.9,1-6.7,2-7.6c1-0.9-0.8-2.1-2-2.2c-1.2-0.1-1-1.9-2.6-1.8
					c-1.6,0.1-1,1.1,0.1,2c1.1,0.9,2.3,1.9,1.8,2.7s-0.3,3.9-1.4,4.2s0-1.3-1.3-1.2c-1.3,0.1-0.1,1.9-1.8,1.4s-3.6-2.3-3.6-2.3
					l-1.2,1c0,0,0.3,3.6-1,3.9c-1.3,0.3-2.4,2.7-2.4,2.7s-0.2,2.7-1.2,3.2s2.6,5.8,2.6,5.8s-1.3,1.3-1.2,2.5
					c0.1,1.2,1.4,2.2,2.5,1.4c1.1-0.8,1.8-2.3,2.4-1.9c0.7,0.4,0.4,2.2,2.1,2.4c1.6,0.2,1-1.5,2.4-1.3c1.4,0.2,2.1-0.2,3,1.1
					c0.9,1.3,3.1,2.1,3.1,2.1s-0.7,2.4-0.1,3.5s-1.1,4.6-1.1,4.6s0.1-4.9-0.3-6c-0.4-1.1-1.4-2.3-1.4-2.3l-1.9,0.7
					c0,0,1.4,2.7,0.1,3.6c-1.3,0.9-0.1-2-1.5-2.5s-3.2-1.6-4.2-3s-2.7,0.1-3.4,1.3c-0.7,1.2,0,1.9-1.8,2.4s-0.9,2.4-2.1,3.3
					c-1.2,0.9-1.9,2.8-2,3.8c-0.1,1,0.1,1.8-1.1,1.5c-1.2-0.2-0.3,2.3-1.3,3.2c-1,0.9-1.4,2.4-1.5,3.5s-0.2,2.4-1.4,2
					s-2.2,2.6-2.5,3.8c-0.3,1.2-2,3.1-3.5,2.4s-0.9-8.1,0.2-9.2s3.3-4.7,3.6-5.6s0.9-1,0-1.6c-0.9-0.7,0-4.9,0-4.9s-1.5-3-2.5-2.6
					c-1,0.3-3.4,4.7-4.6,5.1c-1.2,0.4-2,1.1-2,1.1s-3.1,3.8-2.4,4.7c0.7,0.9,2,1.3,2.7,0.8c0.8-0.5,0-1.8,1.4-1.3
					c1.4,0.4,1.8,1.8,0.7,2.4s-0.9,1.3-2.5,0.9c-1.6-0.4-3.4,1.5-3.4,1.5v1.8l-2.2,0.8l-0.3,3l-3.7,3.8l-1.6-0.3c0,0-3,3.8-3.2,4.8
					c-0.2,1,0.5,2.7,0.5,2.7l1.4,0.8l-0.3,1.1l2.1-0.2l-0.3,1.8l2.2,0.1l-0.1,3.1l2.6,2.1c0,0-0.9,1.1,0.2,1.4
					c1.1,0.3,2.1,1.9,0,1.6c-2.1-0.2-2.3-0.8-4.1,0s-2,1.8-4.6,2s-2.4,1.3-3.1,2.6c-0.7,1.3-1.6,1.2-1.5-0.7
					c0.1-1.9,3.4-7.8,3.4-7.8h1.4c0,0,2.1-3.5,1.1-3.8c-1-0.3-5.3-2-5.1-3.1c0.1-1.1,0.4-3.4-0.8-3.6s-4.6,2.2-6,3.5
					c-1.4,1.3-16.4,14.1-16.4,14.1h-2l-5.5,4.5c0,0-2.4,3.8-2,5c0.4,1.2,1.6,4.5,0.5,5c-1.1,0.5-2.5,2.1-2.5,2.1l1.3,1.2l3.6,0.8
					c0,0,3.4,4.7,2.8,5.1c-0.5,0.4-3-3.3-3.9-3c-1,0.3,0.1,4.5,5.8,4.2c0,0,6.2,3.3,6.5,5.1s2.3,2.8,0.8,4.7
					c-1.5,1.9-3.6,2.7-3.1,4.8c0.5,2.1,1.1,3.3,0.4,4.4c-0.7,1.1,2.4,2,1.4,3.3c-1,1.3-3.6,0.8-2.7,1.8c0.9,1,2.5,0.4,3.4-0.2
					c0.9-0.7,4.9,1.6,5.4,3.2c0.4,1.5-0.4,7.2,1.6,8.1c2.1,0.9,2.8,1.4,2.8,2.6c0,0.9,0.8,2.2,1.6,3l26.8-21.8L1011,341l20.3-21
					l14-17l5.3-13.6l9.6-10L1068.6,259.8z"/>
        <path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M892.6,479.6l27.3-50.5l23.6-24.6l13.7-15.9
					c-1.5-1-1.6-2.5-2.6-4.1c-1-1.5-3.6-0.9-3.6-2.5c0-1.6,0.5-7.2-0.8-8.3c-1.3-1.1-2.3-1.4-4.6-1.1c-2.3,0.3-4.9,0.2-4.2-1.8
					c0.8-2,0.9-2.4,2.3-2.5s-2.3-1.5-0.9-2.8s0-2.6-0.4-4.2c-0.4-1.5,2.1-4.2,3.2-4.9c1.1-0.8,1.4-3.5-3.2-4.2
					c-4.6-0.7-5.3-0.3-8.1-2.3c-2.8-2-4.1-2.2-4.7-4.9c-0.7-2.7-0.5-4.7-2.8-5.7c0,0,0.2-1.2,1.2-1.3c1-0.1-0.7-3.3-0.1-4.8
					c0.5-1.5-4.4-0.9-7-0.2c-2.6,0.7-6,5-5.4,5.5c0.7,0.4,3.2-1.1,3.5-0.4c0.3,0.7-2.2,1.4-1.3,2.3c0.9,0.9,1.9,1.9-0.1,1.6
					c-2-0.2-0.8-1.5-2.8-1.4c-2.1,0.1-2.2,0.2-2.3-1.2c-0.1-1.4-1.1-3-4.3-2.5s-1.9,2.4-3.4,2.2c-1.5-0.2-6.2-1.2-7-2.1
					c-0.8-0.9-1.5,2.7-3.4,3.4l1.4,1.9l-0.3,4.4l-1.3,1.1l-0.4,4.9l-1.2-1.6l-1.2-0.1c0,0,0.9-4.9-0.5-4.7c-1.4,0.2-9.7,1.8-9.7,1.8
					s-0.1,2.1,1.1,2.3c1.2,0.2,1.3,1.1,0.8,1.8c-0.4,0.5-0.9,1.5-0.8,2.4c-0.4-0.7-1.1-1.5-2.1-1.3c-1.6,0.4-1.6,3-3.2,2.4
					c-1.5-0.5,0.5-3.7,0.1-4.4c-0.4-0.7-1.6-1.8-1.2-3.2s0.4-3.4-0.9-3.4s-5.4,3.4-6.4,4.4c-1,1-2.8,5.7-5.3,6.6
					c-2.4,0.9-4.2,2.4-4.2,2.4l0.2,1.4l-3-0.2l-2,2.2l-3.2-0.3l-1.3-1.3l-2.2,0.5l-11.6-1.1l-1.3-0.8l-1.6,1l-0.3,4.8l-1.9,1.6
					l0.1,2.2l4.1,6.4l-0.4,3.3l-1.6,1.6l-0.8,2.1c0,0,0.8,2.5,0.9,3.5c0.1,1-0.2,3-0.2,3s-1.6,0.2-2.5,1.6c-0.9,1.4-2.6,4.6-2.6,5.9
					h-1.3l-1.6,5.3l-1.8,0.9c0,0-1.3,4.8-4.4,6.7c0,0,1,2.1-1.4,2c0,0,0,3.5-1,3.7c-1,0.2-1.2,0.9-1.3,1.6c-0.1,0.8-3.7,5.4-4.5,6.5
					c-0.8,1.1-3.7,7.1-4.1,11.1c-0.3,3.9,2.8,7.7,3.1,8.5s2.7,3.2,2.7,3.2l-0.4,5.4l-1.3,3.4c0,0-0.3,8,0.5,9.9
					c0.9,1.9,2.7,9.4,2,11.3c-0.8,1.9-1.4,7.2,1.1,9c2.5,1.8,4.4,5.4,4.4,6.8c0,1.4,1.3,1.6,2.2,3.5c0.9,1.9,3.2,9.3,3.2,9.3
					s-1.3,6.4-0.9,7.7s-1.4,2-0.4,4.6c1,2.6,2,7.4,6,11.6l0,0.1l0.9,2.5l1.6,1.5l3.7,6.6l-0.1,3.4c0,0,1.6,0,1.1,1.1
					c-0.5,1.1-0.4,2.8-2.3,3.5c-1.9,0.7-0.2,5.6-1.6,6.8c-1.4,1.2-1.5,4.2-0.1,5.1c1.4,1,1.2,1.1,2.3,2.6s1.6,2.4,1.4,3.8
					c-0.2,1.4,0.8,2.6-1,2.6s-0.3,2.3-2,3.5c-1.6,1.2,0,1.8-1.5,3c-1.5,1.2-5.5,8.3-5.5,8.3s-2.7,4.8-3.1,7.2
					c-0.3,2.4-4.6,3.6-4.6,3.6s-0.2,2.8-1.5,2.8c-1.3,0-2.1,3.9-1.8,5.4c0.3,1.4-2.8,8.4-5,8.5c-2.2,0.1-3,2-3.7,3.5
					c-0.8,1.5-2.1,4.2-2.6,6.8c-0.5,2.6,0.2,4.6-1.3,5.3s0.5,3-1.3,6.4c-1.9,3.4-2.4,5.9-4.1,6.6c-1.6,0.7-6,0.2-8.1,0.4
					c-2.1,0.2-2.8,2.4-4.2,3c-1.3,0.5-1.6,0.4-2.4,3.3c-0.8,2.8-1.2,9.1-0.3,11.1s1.3,4.5,1.3,4.5l11,0.4l5.3,4.7l14,2.7l24.6,3.3
					l17.6-9.3l14.3-12l13-12l9-18.6l1.3-11l-9-67.8l0.3-32.6L892.6,479.6z M831,525c-0.1,0.3-0.7,0.9-1.5,1.5
					C830.3,525.9,830.9,525.4,831,525c0-0.1,0.1-0.2,0.1-0.2C831.1,524.9,831,524.9,831,525z"/>
        <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10"
                  points="882.1,354.3 903.6,388.5 933.5,413.5 				"/>
			</g>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="830,856.1 836.4,847.4 854.7,836.1 856.7,829.8
				861.1,827.5 862.1,819.5 868,819.2 872.7,809.5 870.4,805.9 868.7,797.2 865,795.9 860.7,801.5 854.4,799.2 850.7,789.6
				850.1,782.6 843.1,783.2 845.4,774.9 845.4,765.3 838.4,764 834.5,760.3 826.5,761.6 828.5,749.3 820.5,751.3 812.5,750.3
				812.5,757 811.5,763 808.2,761 805.5,764.3 799.9,765.3 797.9,768.6 792.1,765.7 			"/>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="985.4,804 976.8,796.2 963.1,795.9 			"/>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10"
                points="956.5,794.9 951.5,797.2 933.2,807.2 924.2,853.7 			"/>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="891.6,619 896.3,608.7 900.6,605.3 905.3,606
				921.2,601.3 928.2,606 929.9,618.6 923.9,623.3 921.6,629.9 916.9,633.3 919.6,643.9 928.6,644.6 939.9,643.6 954.2,649.9
				971.1,669.2 975.8,685.8 992.7,690.1 992.1,704.4 989.1,710.1 985.4,727 981.4,737.3 977.8,744.7 973.5,747.7 974.1,752.3
				979.4,752 980.1,758.3 976.4,765.3 970.8,768.3 969.1,773.6 975.8,783.2 972.5,789.9 963.1,795.9 956.5,794.9 944.9,788.2
				938.2,785.6 931.9,775.6 924.9,775.9 912.9,767.6 904.3,772.9 895.6,782.2 893.3,766.6 904.3,754.6 909.6,748.3 899,744.7
				893.6,749.3 882.7,751 875,743.3 867.4,740.7 866.4,728 874.4,710.7 871,709.7 867,712.7 859.1,711.7 851.7,712.4 846.8,708.4
				848.4,700.4 853.7,697.1 851.7,692.1 847.4,688.5 846.8,679.2 855.4,661.5 			"/>
      <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="1013.4" y1="714.8" x2="989.1" y2="710.1"/>
      <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="976.4" y1="765.3" x2="998.6" y2="775"/>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="971.1,669.2 978.8,634.3 993.7,612.3 1000.4,599.3
				1016,621.3 1002.7,673.2 1001.9,674.6 			"/>
      <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="1001.9" y1="674.6" x2="992.7" y2="690.1"/>
      <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="1043.1" y1="625.1" x2="1016" y2="621.3"/>
      <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="1002.7" y1="673.2" x2="1020.6" y2="687.5"/>
      <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="921.2,601.3 922.6,584.4 935.2,543.8 961.5,543.8
				984.1,544.5 995.4,540.5 1013.7,542.1 1012.4,567.8 1005,590.4 1000.4,599.3 			"/>
      <g>
				<polygon fill="none" stroke="#FFFFFF" stroke-miterlimit="10"
                 points="1051.1,592.5 1051.1,592.5 1051.1,592.5 				"/>
        <polyline fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="1095.7,429.1 1082.9,428.4 1074.5,425.1 1065.2,440.7
					1054.3,486.9 1057.3,508.9 1053.3,513.5 1044,547.5 1035.6,558.1 1037.3,585 1033.3,601.7 1041.3,605.7 1047.4,605.8 				"/>
			</g>
      <polygon fill="none" stroke="#FFFFFF" stroke-miterlimit="10" points="979.8,492.3 982.1,514.9 987.4,516.5 1006,523.9
				1017,522.5 1019,513.9 1022.7,500.9 1014.4,495.6 1009.7,487.9 996.7,486.3 			"/>
      <path fill="none" stroke="#FFFFFF" stroke-width="1" stroke-miterlimit="10" d="M1149.9,307.1c-0.7-1.3-1.2-2.6-1.5-3.7
				c-0.3-1.1-0.8-2.2-2.1-4.3c-1.3-2.1-4.4-4.8-4.7-7.2c-0.3-2.4-2.2-3.3-2.8-5.6c-0.7-2.3-1.6-10.4-2.7-12.9
				c-1.1-2.5-2-10.5-1.1-13.4c0.9-2.8,1-6.8-1.6-7.6l0.2-11.1l-1.4-4.2c0,0,0.5-10.6-0.4-11.9c-1-1.3-2.1-3.7-2.1-3.7
				s0.9-2.5-0.4-3.3c-1.3-0.8-1.3-3.7-2.1-5.7c-0.8-2-1.8-3.5-1.2-5c0.5-1.5,1.2-4.6-1.5-4.1c0,0,0.1-2.7-0.9-4.3
				c-1-1.5-2.8-3.5-2.3-6c0.5-2.5,0-7.9,0-7.9s-1.2-4.7-3.3-4.6c-2.1,0.1,0.8-1.5-0.4-2.3s-2.3-0.8-2.7-1.8c-0.4-1-0.9-3.7-2.3-4.8
				c-1.4-1.1-2.3-2.2-3-0.5c-0.7,1.6-0.8,2.5-2.2,2c-1.4-0.5,0.5-2.8,0-3.8c-0.5-1-2.3-4.7-4.1-4.3c-1.8,0.4-2.3-1.9-1.5-3.1
				c0.8-1.2-1.3-3.1-0.1-4.3c1.2-1.2,0.7,2.2,1.5,0.9s-0.1-5.3-1.6-6.7c-1.5-1.4-1.1-3.9-3.2-3.7c-2.1,0.2-1.9,2-2.8,1.5
				c-1-0.4,0-2.8-0.9-4.3c-0.9-1.4-0.9-3.4-2.6-3c-1.8,0.4,1.1,3.2-1.2,3s-1.2-2.5-2.6-2.1c-1.4,0.4-1.1,2.2-3.2,2.1
				s-1.6-0.8-1.5-1.4c0.1-0.7,0.7-1.5,1.3-1c0.7,0.5,1.5,0.7,2-0.3c0.4-1-0.7-0.9-1.6-1.5s0.2-1.3-0.1-2c-0.3-0.7-0.1-1,0.7-2
				c0.8-1,1.1,2.4,2.4,2.1c1.3-0.3,0.4-2.2,1.9-1.2c1.4,1,1.1,2.8,2.3,2.2c1.2-0.7-0.5-3.1-0.8-3.9c-0.2-0.9,1-2.1,0.3-2.8
				c-0.7-0.8-3.1-0.1-2.8-1.1c0.2-1-0.1-4.5-1.2-5.2l-2.2-1.5c0,0-2.2,1.8-2.4,2.5c-0.2,0.8,0,2,0,2s-1-0.5-1.5,0.1
				c-0.5,0.7-0.2,1.2-0.2,1.2s1.8,0,1.5,0.5c-0.2,0.5,0,0.9,0.9,1.5c0.9,0.7-0.8,2-1.4,0.8s-1.6-2.7-2.4-2.6c-0.8,0.1,0,0.9-1.1,1.1
				c-1.1,0.2-1.6,0.4-1.8,1.3c-0.1,0.9,1,0.3,1.4,0.9c0.4,0.5,0.8-0.8,1.4,0s-0.2,1.1,1.3,0.8c1.5-0.3,1.6,2.1,0.1,1.9
				c-1.5-0.2-1.8,1.8-1.1,2.7c0.7,1,0.8,1.8-0.1,1.6c-0.9-0.1-0.2,1.2-1.1,0.9c-0.9-0.3-0.4-0.9-1.4-0.8c-1,0.1-0.1,2.7-1.3,3
				s-0.3-1-1.1-1c-0.8,0,0,1.3-0.9,1.2c-0.9-0.1-1,0-0.9,1.3c0,0-0.5-0.4-1.5,0c-1,0.4-1.8,1.6-1.8,1.6s0.7,1.9,0.3,2.4
				c-0.3,0.5-1,3.2-1.4,4.3c-0.4,1.1-1.9,1-2.6,0.1c-0.8-0.9-1-3.4-2.2-3.5c-1.2-0.1-2.8,0.1-4.3-0.7c0,0-1.3,0.4-2,1.3
				s0.7,2.1,1.6,1.3c1-0.8,1.3,0,1.1,0.9c-0.2,0.9-0.8,1.2,1,1.9c1.8,0.7,1.9,2.1,1.4,2.6c-0.4,0.5,0.5,1.1,0.5,1.1s0.9-1.3,1.8-0.9
				c0.9,0.4,0.4,1.3-0.2,1.9c-0.7,0.5,0.1,1.2,0.1,2c0,0.8-1.8,1.5-0.2,2.1c1.5,0.5,1.8,0.7,1.5,1.6s0.2,4.2,0.2,4.2
				s1.9,1.1,2.3,2.5c0.4,1.4-0.3,4.7-0.5,5.8c-0.2,1.1-0.8,0.3-1.3,1.5c-0.5,1.2,0.7,1.9-0.5,2.2s-1,0.7-1.3,1.5
				c-0.3,0.9-0.2,1.2-1.1,1.6c-0.9,0.4,0.2,2.2-0.7,2.6c-0.9,0.4-1.3,1-1.3,3c0,2-1.2,4.3,0.3,5.4c1.5,1.1,0.8,1.9,0.8,1.9
				s-0.9-0.3-1.6,0.5c-0.8,0.9-1.2,3.1-1.2,3.1s1.6,0.8,0.7,1.3c-1,0.5-1.2,0.2-2,1.3s-0.5,2-1.5,1.6c-1-0.3-0.8,0.9-2.7,1
				c-2,0.1-5.1,0.8-6,0.1s-3-3-3.3-3.8c-0.3-0.9-1.5-0.5-1.4,0.8c0.1,1.3-0.1,2.4,1,3.3c1.1,0.9,0.1,1.8,1.1,3c1,1.2,2.1,0.7,1,1.2
				c-1.1,0.5-2.3-0.9-2.3-0.9s0.1,0.9-1.2,0.7c-1.3-0.2,0,1.1-1.5,0.9c-1.5-0.2-1.3,1.2-2.5,0.9c-1.2-0.3-1.4-1.2-2.2-0.5
				c-0.8,0.7-1.3,1.4-0.5,2.1c0.8,0.7,1.1,2,0.3,2.6c-0.8,0.7-1.5,2.3-1.1,3.2c0.4,0.9-0.1,2.4,0.3,3.2c0.4,0.8,0.7,2-0.7,2.4
				c-1.3,0.4-1.3,1.8-3.6,1.1c-2.3-0.7-3.7-3.5-3.7-3.5s0-3.2-0.8-4.1c-0.8-0.9-0.8-3.6-0.8-3.6s-2.5-0.1-3-1.5
				c-0.4-1.4-1-3.4-3.6-3.1c0,0,1.8,1,1,1.8c-0.8,0.8,0,1.9-1.3,1.6c-1.3-0.2-1.3-2-1.2-2.7c0.1-0.8-2-1-1.6,0.7
				c0.3,1.6,0.1,2.5-1.5,2.7c-1.6,0.2-2.4,1-2.3,2c0.1,1,0.2,3.4,1.2,3.8c1,0.4,1,1.4-0.2,1.4c-1.2,0-1,1.6-1.3,2.3
				c-0.3,0.7-0.7,1.4,0.3,2.3v2.6l1.6,3c0,0,2.2,0.7,3.7,1.6c1.5,1,1.6,1.6,0.8,2c-0.9,0.3-0.1,1.3-1.6,1.2c-0.2,0-0.4,0-0.5,0
				c-0.1,0-0.3,0-0.4,0c0.1,0,0.2,0,0.4,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
				c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2
				c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.8,1,3.9,3.3,4.7,3.7c0.8,0.4,1.1,2,0.1,2.4c-1,0.4,1.1,1.5,0,2.2
				c-1.1,0.7-2.2,3.1-1.4,4.1c0.8,1,1,2,0.1,2.3c-0.9,0.3-0.9,1.3-1,2.3c-0.1,1-1.4,0.4-1.3-1.4c0.1-1.9,1-6.7,2-7.6
				c1-0.9-0.8-2.1-2-2.2c-1.2-0.1-1-1.9-2.6-1.8c-1.6,0.1-1,1.1,0.1,2c1.1,0.9,2.3,1.9,1.8,2.7c-0.5,0.9-0.3,3.9-1.4,4.2
				c-1.1,0.2,0-1.3-1.3-1.2c-1.3,0.1-0.1,1.9-1.8,1.4c-1.6-0.4-3.6-2.3-3.6-2.3l-1.2,1c0,0,0.3,3.6-1,3.9c-1.3,0.3-2.4,2.7-2.4,2.7
				s-0.2,2.7-1.2,3.2c-1,0.4,2.6,5.8,2.6,5.8s-1.3,1.3-1.2,2.5c0.1,1.2,1.4,2.2,2.5,1.4c1.1-0.8,1.8-2.3,2.4-1.9
				c0.7,0.4,0.4,2.2,2.1,2.4c1.6,0.2,1-1.5,2.4-1.3c1.4,0.2,2.1-0.2,3,1.1c0.9,1.3,3.1,2.1,3.1,2.1s-0.7,2.4-0.1,3.5
				s-1.1,4.6-1.1,4.6s0.1-4.9-0.3-6c-0.4-1.1-1.4-2.3-1.4-2.3l-1.9,0.7c0,0,1.4,2.7,0.1,3.6c-1.3,0.9-0.1-2-1.5-2.5
				c-1.4-0.5-3.2-1.6-4.2-3c-1-1.3-2.7,0.1-3.4,1.3c-0.7,1.2,0,1.9-1.8,2.4c-1.8,0.5-0.9,2.4-2.1,3.3c-1.2,0.9-1.9,2.8-2,3.8
				c-0.1,1,0.1,1.8-1.1,1.5c-1.2-0.2-0.3,2.3-1.3,3.2c-1,0.9-1.4,2.4-1.5,3.5c-0.1,1.1-0.2,2.4-1.4,2c-1.2-0.4-2.2,2.6-2.5,3.8
				c-0.3,1.2-2,3.1-3.5,2.4c-1.5-0.7-0.9-8.1,0.2-9.2c1.1-1.1,3.3-4.7,3.6-5.6c0.3-0.9,0.9-1,0-1.6c-0.9-0.7,0-4.9,0-4.9
				s-1.5-3-2.5-2.6c-1,0.3-3.4,4.7-4.6,5.1c-1.2,0.4-2,1.1-2,1.1s-3.1,3.8-2.4,4.7c0.7,0.9,2,1.3,2.7,0.8c0.8-0.5,0-1.8,1.4-1.3
				c1.4,0.4,1.8,1.8,0.7,2.4c-1.1,0.7-0.9,1.3-2.5,0.9c-1.6-0.4-3.4,1.5-3.4,1.5v1.8l-2.2,0.8l-0.3,3l-3.7,3.8l-1.6-0.3
				c0,0-3,3.8-3.2,4.8c-0.2,1,0.5,2.7,0.5,2.7l1.4,0.8l-0.3,1.1l2.1-0.2l-0.3,1.8l2.2,0.1l-0.1,3.1l2.6,2.1c0,0-0.9,1.1,0.2,1.4
				c1.1,0.3,2.1,1.9,0,1.6c-2.1-0.2-2.3-0.8-4.1,0c-1.8,0.8-2,1.8-4.6,2c-2.6,0.2-2.4,1.3-3.1,2.6c-0.7,1.3-1.6,1.2-1.5-0.7
				c0.1-1.9,3.4-7.8,3.4-7.8h1.4c0,0,2.1-3.5,1.1-3.8c-1-0.3-5.3-2-5.1-3.1c0.1-1.1,0.4-3.4-0.8-3.6c-1.2-0.2-4.6,2.2-6,3.5
				c-1.4,1.3-16.4,14.1-16.4,14.1h-2l-5.5,4.5c0,0-2.4,3.8-2,5c0.4,1.2,1.6,4.5,0.5,5c-1.1,0.5-2.5,2.1-2.5,2.1l1.3,1.2l3.6,0.8
				c0,0,3.4,4.7,2.8,5.1c-0.5,0.4-3-3.3-3.9-3c-1,0.3,0.1,4.5,5.8,4.2c0,0,6.2,3.3,6.5,5.1c0.2,1.9,2.3,2.8,0.8,4.7
				s-3.6,2.7-3.1,4.8c0.5,2.1,1.1,3.3,0.4,4.4c-0.7,1.1,2.4,2,1.4,3.3c-1,1.3-3.6,0.8-2.7,1.8s2.5,0.4,3.4-0.2
				c0.9-0.7,4.9,1.6,5.4,3.2c0.4,1.5-0.4,7.2,1.6,8.1c2.1,0.9,2.8,1.4,2.8,2.6c0,1.2,1.4,3.2,2.5,3.6c1.1,0.4,0.2,2.8,1.2,4.2
				c1,1.3,0.7,3.3-0.1,4.4c-0.8,1.1-1.2,4.1-2.4,5.1c-1.2,1.1-2.4,2.8-3.4,2.4c-1-0.4,0.8-2.7,2.1-3.7c1.3-1,2.3-2.8,2.1-4.2
				c-0.2-1.3,1.6-2.8,0.7-4.4c-1-1.5-0.5-2.6-2.1-3.6c-1.5-1-1.6-2.5-2.6-4.1c-1-1.5-3.6-0.9-3.6-2.5c0-1.6,0.5-7.2-0.8-8.3
				c-1.3-1.1-2.3-1.4-4.6-1.1c-2.3,0.3-4.9,0.2-4.2-1.8c0.8-2,0.9-2.4,2.3-2.5s-2.3-1.5-0.9-2.8c1.4-1.3,0-2.6-0.4-4.2
				c-0.4-1.5,2.1-4.2,3.2-4.9c1.1-0.8,1.4-3.5-3.2-4.2c-4.6-0.7-5.3-0.3-8.1-2.3c-2.8-2-4.1-2.2-4.7-4.9c-0.7-2.7-0.5-4.7-2.8-5.7
				c0,0,0.2-1.2,1.2-1.3c1-0.1-0.7-3.3-0.1-4.8c0.5-1.5-4.4-0.9-7-0.2c-2.6,0.7-6,5-5.4,5.5c0.7,0.4,3.2-1.1,3.5-0.4
				c0.3,0.7-2.2,1.4-1.3,2.3c0.9,0.9,1.9,1.9-0.1,1.6c-2-0.2-0.8-1.5-2.8-1.4c-2.1,0.1-2.2,0.2-2.3-1.2c-0.1-1.4-1.1-3-4.3-2.5
				c-3.2,0.4-1.9,2.4-3.4,2.2c-1.5-0.2-6.2-1.2-7-2.1c-0.8-0.9-1.5,2.7-3.4,3.4l1.4,1.9l-0.3,4.4l-1.3,1.1l-0.4,4.9l-1.2-1.6
				l-1.2-0.1c0,0,0.9-4.9-0.5-4.7c-1.4,0.2-9.7,1.8-9.7,1.8s-0.1,2.1,1.1,2.3c1.2,0.2,1.3,1.1,0.8,1.8c-0.4,0.5-0.9,1.5-0.8,2.4
				c-0.4-0.7-1.1-1.5-2.1-1.3c-1.6,0.4-1.6,3-3.2,2.4c-1.5-0.5,0.5-3.7,0.1-4.4c-0.4-0.7-1.6-1.8-1.2-3.2c0.4-1.4,0.4-3.4-0.9-3.4
				s-5.4,3.4-6.4,4.4s-2.8,5.7-5.3,6.6c-2.4,0.9-4.2,2.4-4.2,2.4l0.2,1.4l-3-0.2l-2,2.2l-3.2-0.3l-1.3-1.3l-2.2,0.5l-11.6-1.1
				l-1.3-0.8l-1.6,1l-0.3,4.8l-1.9,1.6l0.1,2.2l4.1,6.4l-0.4,3.3l-1.6,1.6l-0.8,2.1c0,0,0.8,2.5,0.9,3.5c0.1,1-0.2,3-0.2,3
				s-1.6,0.2-2.5,1.6c-0.9,1.4-2.6,4.6-2.6,5.9h-1.3l-1.6,5.3l-1.8,0.9c0,0-1.3,4.8-4.4,6.7c0,0,1,2.1-1.4,2c0,0,0,3.5-1,3.7
				c-1,0.2-1.2,0.9-1.3,1.6c-0.1,0.8-3.7,5.4-4.5,6.5c-0.8,1.1-3.7,7.1-4.1,11.1s2.8,7.7,3.1,8.5c0.2,0.9,2.7,3.2,2.7,3.2l-0.4,5.4
				l-1.3,3.4c0,0-0.3,8,0.5,9.9c0.9,1.9,2.7,9.4,2,11.3c-0.8,1.9-1.4,7.2,1.1,9s4.4,5.4,4.4,6.8s1.3,1.6,2.2,3.5
				c0.9,1.9,3.2,9.3,3.2,9.3s-1.3,6.4-0.9,7.7c0.4,1.3-1.4,2-0.4,4.6c1,2.6,2,7.4,6,11.6l0,0.1c1.2-0.8,2.2-1.7,2.4-2.1
				c0.1-0.3,0.5-0.5,1-0.5c-0.5,0-0.9,0.1-1,0.5c-0.2,0.4-1.2,1.3-2.4,2.1l0.9,2.5l1.6,1.5l3.7,6.6l-0.1,3.4c0,0,1.6,0,1.1,1.1
				c-0.5,1.1-0.4,2.8-2.3,3.5c-1.9,0.7-0.2,5.6-1.6,6.8c-1.4,1.2-1.5,4.2-0.1,5.1c1.4,1,1.2,1.1,2.3,2.6c1.1,1.5,1.6,2.4,1.4,3.8
				c-0.2,1.4,0.8,2.6-1,2.6c-1.8,0-0.3,2.3-2,3.5s0,1.8-1.5,3c-1.5,1.2-5.5,8.3-5.5,8.3s-2.7,4.8-3.1,7.2c-0.3,2.4-4.6,3.6-4.6,3.6
				s-0.2,2.8-1.5,2.8c-1.3,0-2.1,3.9-1.8,5.4c0.3,1.4-2.8,8.4-5,8.5c-2.2,0.1-3,2-3.7,3.5c-0.8,1.5-2.1,4.2-2.6,6.8
				c-0.5,2.6,0.2,4.6-1.3,5.3c-1.5,0.7,0.5,3-1.3,6.4c-1.9,3.4-2.4,5.9-4.1,6.6c-1.6,0.7-6,0.2-8.1,0.4c-2.1,0.2-2.8,2.4-4.2,3
				c-1.3,0.5-1.6,0.4-2.4,3.3s-1.2,9.1-0.3,11.1c0.9,2,1.3,4.5,1.3,4.5l-1.1,0.9c0,0-1.1-1.8-2-1.2c-0.9,0.5-3.9,4.7-4.4,6.1
				c-0.4,1.4-1.2,4.1,0.2,5.1c1.4,1.1-2.2,3.9-2.3,2.4c-0.1-1.5-0.1-2.1-1.3-1.1c-1.2,1,1.3,1.8,0.3,2.7c-1,1-1.9-0.2-2,1
				c-0.1,1.2-0.2,4.6-1.2,5.7c-1,1.1,0.8,2.7,0.7,3.8c-0.1,1.1,0.2,2,1.5,1.5c1.3-0.4,2.3,0.2,2,1.1c-0.3,0.9,1.2,2.1-0.1,2.5
				c-1.3,0.4-2.3-1.1-3.4-0.4c-1.1,0.7-0.5,3.6-0.8,5s2,2.3,2.1,4.3c0.1,2,0.3,4.2,1.4,5.1c1.1,1-0.9,3.6,0.4,6.1
				c1.3,2.5,1.9,5.1,2,7.3c0.1,2.2,0.2,5.3,2.1,6.8s3.5,3.7,3.5,3.7s-0.8,1.3,0.9,2.2c1.6,0.9,1.6,2,3.6,3.1c2,1.1,4.3,3.5,3.7,5.4
				c-0.5,1.9,2,4.2,1.3,5.7c-0.7,1.5-1.6,4.6,0.1,5.7c1.8,1.1,6.5,5.5,6.6,7.1c0.1,1.6,1,3.3-0.7,3.7c0,0,0.4,1.3,1.9,1.9
				c1.4,0.5-0.3,1.4-1.8,1.1c-1.4-0.3-1.4,1.1-3.7,1.2c-2.3,0.1-1.1,9.3-0.5,10.6c0.5,1.3,0,2.3,0,3.3c0,1,0.4,7,1.1,8.8
				c0.7,1.8-0.7,17.5,0.1,19.2c0.8,1.6,1.4,3.4,3.5,4.1c2.1,0.7,9.2,9.1,10.3,10.9c1.1,1.9,0.7,4.4-0.5,5c0,0,1,3.7,3.4,5.5
				c2.4,1.8,1,4.5,1.6,5.6c0.7,1.1,0,2.2,1.4,3.4c1.4,1.2,1.9,3.6,4.5,3.6c2.6,0,4.3,0.5,6.4,2.3c2.1,1.8,3.8,1.5,4.6,2.8
				c0.8,1.3,4.2,3.7,2.2,4.3c-2,0.5-3,0.7,1.1,1.6c4.1,1,9.1-0.3,12.4,1.1c3.3,1.4,12.6,3.8,12.6,3.8l4.3,5.3l8.8,4.5
				c0,0-1.2,3.7,1.9,3.1c3.1-0.7,7.1-2.5,11.3-1.9c4.2,0.7,5.5,0.5,8.8-0.9c3.3-1.4,4.7-1.8,6.4-3.7c1.6-2,13.6-8.4,16.2-9.4
				c2.6-1,14.8-7.6,19.2-7.6c4.4,0,4.4,0.2,6-0.4c1.6-0.7,5.8,0.8,7.3,0.5c1.5-0.2,0.9,1.9,3.1,0.8s6.2-1.8,7.6-3.4
				c1.3-1.6,1.9-2.6,3.7-3.3c1.9-0.7,1.8-1.6,3.5-0.7c1.8,1,1,1.2,2.2-0.2c1.2-1.4,0.7-3.4,3.4-3.3c2.7,0.1,3.9-0.5,3.7-2.2
				c-0.2-1.6-1.1-2,0.1-2.4c1.2-0.4-0.1-0.9,1.1-2.5s2.3-4.2,2.6-5.7c0.3-1.5,3.8-10.6,5.3-13.6c1.4-3,1.8-11.6,3.6-11.6
				c1.9,0,1.6-1.3,2.1-3.4c0.2-0.9,0.5-2.3,0.9-3.7c0,0,0,0,0,0c0.6-2,1.3-4,1.9-4.7c1.1-1.2-1-1.2,0.2-2c1.2-0.8,0.9,0.1,2-1.4
				c1.1-1.5,5.6-10.4,5-12.6c-0.5-2.2,1.1-5.8,1.8-7.8c0.7-2,1.2-6.7,2.3-9.1c1.1-2.4,5.7-13.2,5.1-16.1c-0.5-2.8-0.3-5,1.4-6.9
				c1.8-1.9,1.6-4.9,1.8-6.2c0.1-1.3,2.3-2.5,1.9-4.5s0.5-11.7,1.4-14c0.9-2.3,0.9-7.1,1.8-8.4c0.9-1.3,3.3-3.7,3-6.6
				c-0.3-2.8,1.9-10.1,3.8-12.4c2-2.3,1.8-5.8,3.4-8c1.6-2.2,5-11.4,5.4-13.9c0.3-2.5,2.3-5,2.6-7.7c0.3-2.6,4.2-11.7,4.7-13.7
				c0.5-2,0-4.2,1.4-6.8c1.4-2.6,4.2-11.4,2.4-11.1c-1.8,0.3-1.5-0.8,0.1-1.8c1.6-1,1.1-3.3,1.8-4.8c0.7-1.5,3.5-12.3,4.6-16
				c0.9-3.1,1.1-4.3,1.9-6.9c0,0,0,0,0,0c-0.4,0-0.7,0-1,0c-1.4,0.1-2.7,0.1-3.9,0.2c1.1-0.1,2.5-0.1,3.9-0.2c0.3,0,0.7,0,1,0
				c0.2-0.5,0.3-1,0.5-1.7c1.3-3.9,7.7-18.2,8.1-20c0.4-1.9,0.8-5.7,3-7.4c2.2-1.8,0.8-3.2,1.3-8.7c0.5-5.5,1.5-8.2,1.5-8.2
				s3.2-6.9,4.3-9.6c1.1-2.7,3.1-4.7,2.8-8c-0.2-3.3,3.8-13.2,5.5-15.2c1.6-2,2.1-4.5,3-7c0.9-2.5,2.8-10.5,4.6-14.1
				c1.8-3.6,2.8-6,3.5-8.1c0.7-2.1,2.1-1.3,1.8-4.7c-0.3-3.4,1.8-8.5,1.6-10.4c0,0,3-1.6,2.5-3.3c-0.4-1.6-1.3-1.5-1.3-1.5
				s0.3-3,1-4.3c0.7-1.3-0.4-3.5,0.3-5.4c0.8-1.9,6-12.2,4.5-14.2c-1.5-2.1-2.6-4.1-2.5-6.7c0.1-2.6,0.7-3.1-1.1-4.6
				c-1.8-1.5,0-2.4-1.5-4.8c-1.5-2.4,0-7.1,1.6-9.5c1.6-2.4,3.7-8,4.7-10.3c1-2.3,1.9-5.7,3.8-6.5c2-0.8,7.4-2.2,9.4-2.3
				c2-0.1,1.5-1.2,0.3-1.5c-1.2-0.3-3.6-2.5-3.6-4.4c0-1.9-0.2-3.6,2.1-3.8s0.4-2.3,2.1-4.1c1.6-1.8,1.6-3.2,2-4.9
				c0.3-1.8,2.1-5.5-0.1-5.3c-2.2,0.2-0.8-2.8-1.3-4.9s3.8-5.9,1.3-7.8c-2.5-1.9-2.8-2-4.7-2c-1.9,0-1.4-1.9-2.3-3.6
				c-0.9-1.8-1.8-2.1-1.2-4.2c0.5-2.1,3.1-4.7,1.5-6.7c-1.5-2-1.4-9.1-4.1-10.5c0,0,1.5-3-0.1-4.5c-1.6-1.5,1.5-5.8,3-8.1
				c1.4-2.3,3.2-2.3,3.2-2.3l1.5,0.9c0,0,3.5-1.8,5.5-1c2,0.8,1.4,5.1,0.5,6.1c-0.9,1,1.1,2.5,2.8,3.3c1.8,0.8,0.1,3.2,0.3,4.7
				c0.2,1.5,3.4,4.6,3.4,4.6l0.1,5.6c0,0,1.3,0.4,2.2,1.2c0.4-1.7,0.9-3.9,1.2-4.9c-0.2,1-0.7,3.1-1.2,4.9c0.4,0.4,0.8,0.9,0.8,1.4
				c0.1,1.6,3.2,0.5,3.6,2.8h3.7c0,0-0.1-1.8,1-2.6c1.1-0.9,0-2.3,1.8-2.7c1.8-0.4,0.7-3,1.9-3.2c1.2-0.2,1.4-1,1.2-2.4
				c-0.2-1.4,7.4-23.1,7.4-23.1S1150.5,308.4,1149.9,307.1z"/>
		</g>
	</g>
</g>
      <g id="LEGENDES">
        <line fill="none" stroke="#ffffff" stroke-width="0.4475" stroke-miterlimit="10"
              x1="677.7" y1="252.6" x2="1034.9" y2="252.6"/>
        <path fill="#FFFFFF"
              d="M1034.8,254.3c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7S1033.8,254.3,1034.8,254.3z"/>
        <text transform="matrix(1 0 0 1 450.9409 257.9854)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Sakalava Boina</text>
			<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
            x1="620.9" y1="469.1" x2="860.1" y2="469.1"/>
				<path fill="#FFFFFF"
              d="M860,470.8c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7S859.1,470.8,860,470.8z"/>
        <rect x="283.1" y="453.6" fill="none" width="261.7" height="29.8"/>
        <text transform="matrix(1 0 0 1 356.8007 472.0808)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Sakalava Menabe</text>
			  <line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="580.9" y1="574.9" x2="968" y2="574.9"/>
				<path fill="#FFFFFF" d="M967.9,576.5c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7
					C966.2,575.8,967,576.5,967.9,576.5z"/>
        <rect x="337.6" y="561.8" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 459.2655 580.2183)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Betsileo</text>
			<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
            x1="580.9" y1="667.6" x2="989.8" y2="667.6"/>
				<path fill="#FFFFFF" d="M989.7,669.3c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7
					C988,668.6,988.8,669.3,989.7,669.3z"/>
        <rect x="337.6" y="654.5" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 473.7211 672.9973)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Tañala</text>
			<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
            x1="590.9" y1="745.4" x2="995.6" y2="745.4"/>
				<path fill="#FFFFFF"
              d="M995.5,747.1c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7S994.6,747.1,995.5,747.1z"/>
        <rect x="337.6" y="730" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 445.2772 748.4229)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Antesaka</text>
			  <line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="590.9" y1="821.2" x2="834.8" y2="821.2"/>
				<path fill="#FFFFFF"
              d="M834.8,822.9c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S833.8,822.9,834.8,822.9z"
        />
        <rect x="337.6" y="807.7" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 445.1215 826.1494)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Mahafale</text>
				<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="962.1" y1="829.3" x2="1208.9" y2="829.3"/>
        <path fill="#FFFFFF" d="M962.2,831c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
          C963.8,830.2,963.1,831,962.2,831z"/>
        <rect x="1223.2" y="817.4" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1223.2084 835.8457)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Antanosy</text>
				<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="962.1" y1="703.6" x2="1208.9" y2="703.6"/>
				<path fill="#FFFFFF"
              d="M962.2,705.3c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7S963.1,705.3,962.2,705.3z"/>
        <rect x="1223.2" y="691.7" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1223.2084 710.1445)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Bara</text>
				<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="1029.9" y1="634.2" x2="1351.4" y2="634.2"/>
				<path fill="#FFFFFF"
              d="M1030,635.9c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7S1030.9,635.9,1030,635.9z"/>
        <rect x="1366.9" y="621.1" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1366.8661 639.5708)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Antemoro</text>
			  <line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="1007" y1="511" x2="1323.6" y2="511"/>
				<path fill="#FFFFFF"
              d="M1007.1,512.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7S1008,512.7,1007.1,512.7z"/>
        <rect x="1337.9" y="499.1" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1337.9351 517.6035)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Imerina</text>
				<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10" x1="1078.8" y1="438.2"
              x2="1352.6" y2="438.2"/>
				<path fill="#FFFFFF" d="M1078.9,439.9c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7
					C1080.6,439.2,1079.8,439.9,1078.9,439.9z"/>
        <rect x="1366.9" y="426.3" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1366.8661 444.7771)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Betsimisaraka</text>
				<line fill="none" stroke="#FFFFFF" stroke-width="0.4475" stroke-miterlimit="10"
              x1="1104.2" y1="196.9" x2="1352.6" y2="196.9"/>
				<path fill="#FFFFFF"
              d="M1104.2,198.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7S1105.2,198.5,1104.2,198.5z"/>
        <rect x="1366.9" y="185" fill="none" width="207.2" height="29.8"/>
        <text transform="matrix(1 0 0 1 1366.8661 203.415)" fill="#FFFFFF" font-family="'Metamorphous'"
              font-size="26px">Antakarana</text>
</g>
</svg>
</div>
</template>

<script>
import SvgRoyaumeContainer from "@/components/svg-royaume-container";
export default {
  name: "carte",
  components: {SvgRoyaumeContainer}
}
</script>

<style lang="less">
#carte{
  >svg{
    width: 100%;
    height: 100%;
    *{
      //pointer-events: none;
    }
  }


  transition: transform 0.25s;
  #LEGENDES{
    transition: all 0.5s;
  }
  &.is-royaume{
    #LEGENDES{
      opacity: 0;
      transform: translateX(-100px);
    }
    transform: translateX(-500px);
  }
}
</style>