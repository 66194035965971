export default class Layout{
    constructor() {
        this.currentPageId=null;
        this.currenRoute=null;
        this.veille=false;
    }
    goHome(){
        if(window.$router.currentRoute
            && window.$router.currentRoute.name !== "home"){
            window.$router.push("/");
        }
    }
    /**
     * Est-on sur la home page?
     * @return {boolean}
     */
    get isHome(){
        return this.currenRoute && window.$router.currentRoute.name === "home";
    }

    /**
     * Est-on sur la page evolution ?
     * @return {boolean}
     */
    get isEvolution(){
        return this.currenRoute && this.currenRoute.name==="evolution";
    }
    goEvolution(){
        window.$router.push({name:"evolution",params:{'lang':window.$lang.currentLocaleCode}});
    }
    /**
     * Est-on sur la page concept ?
     * @return {boolean}
     */
    get isConcept(){
        return this.currenRoute && this.currenRoute.name==="concept";
    }
    goConcept(){
        window.$router.push({name:"concept",params:{'lang':window.$lang.currentLocaleCode}});
    }
    /**
     * Est-on sur la page royaumes ?
     * @return {boolean}
     */
    get isRoyaumes(){
        return this.currenRoute && this.currenRoute.name==="royaumes";
    }
    goRoyaumes(){
        window.$router.push({name:"royaumes",params:{'lang':window.$lang.currentLocaleCode}});
    }

    goPageByIdentifiant(identifiant){
        let r=window.$db.getRecordByIdentifiant(identifiant);
        if(r){
            window.$router.push(r.url);
        }
    }
    /**
     * Est-on sur une page royaume ?
     * @return {boolean}
     */
    get isRoyaume(){
        return this.currenRoute && this.currenRoute.name==="royaume";
    }

    /**
     * Doit on afficher la carte ou non?
     * @return {boolean}
     */
    get showCarte(){
        if(!this.currenRoute){
            return false;
        }
        return this.isRoyaume || this.isRoyaumes;
    }
    /**
     * Doit on afficher le menu ou non?
     * @return {boolean}
     */
    get showMenu(){
        return this.isConcept||this.isRoyaumes||this.isEvolution;
    }




}