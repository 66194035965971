import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import IdleVue from 'idle-vue';
import Layout from "@/Layout";
import LocalesManager from "@/models/LocalesManager";

Vue.config.productionTip = false

import router from './pluggins/router'
import Db from "@/models/Db";

/**
 *
 * @type {VueRouter}
 */
window.$router= router;

/**
 *
 * @type {Layout}
 */
window.$layout=new Layout();
Vue.prototype.$layout = Vue.observable(window.$layout);


window.$db=new Db();
Vue.prototype.$db = Vue.observable(window.$db);

/**
 * gestion des langues
 * @type {LocalesManager}
 */
window.$lang=new LocalesManager();
Vue.prototype.$lang = Vue.observable(window.$lang);
window.$lang.addLocale("mg","Malagasy","flags/flag-mg.png");
window.$lang.addLocale("fr","Français","flags/flag-fr.png");
window.$lang.addLocale("en","English","flags/flag-en.png");
window.$lang.currentLocale="fr";


//traductions et textes d'interface
Vue.use(VueI18n);
let i18n = new VueI18n({
  locale: window.$lang.currentLocale.code,
  messages:require("../public/data/data.json"), // set locale messages
});
/**
 * Permet d'accéder aux traductions i18n
 * @param {string} key
 * @param {string} locale
 */
window.$t=function(key,locale=null){
  return i18n.t(key,locale);
}
window.i18n=i18n;
window.$lang.on("change-current-language",function(){
  i18n.locale = window.$lang.currentLocale.code;
})

//----mise en veille------

window.$idleVueEvents = new Vue()
Vue.prototype.$idleVueEvents = window.$idleVueEvents;
Vue.use(IdleVue, {
  eventEmitter: window.$idleVueEvents,
  idleTime: 5*60*1000,
  keepTracking:true
})
if(document.location.protocol.indexOf("http")>-1){
  window.$db.importWeb(function(){
    new Vue({
      router,
      render: h => h(App),
    }).$mount('#app')
  })
}else{
  window.$db.importLocal(function(){
    new Vue({
      router,
      render: h => h(App),
    }).$mount('#app')
  })
}




