<template>
<div>
  <div class="page-size page-langues">
    <page-corners/>
    <div>
      <h2> Avy any amin’ny foko mankany amin’ny fanjakana</h2>
      <!--
      <h2> Ireo fanjakana lehibe teto</h2>
      <h1>Madagasikara</h1>
      -->
      <languages/>
    </div>
  </div>
</div>
</template>

<script>
import Languages from "../langues/languages";
import PageCorners from "../decoration/PageCorners";
export default {
  name: "page-langues",
  components: {PageCorners, Languages}
}
</script>


<style lang="less">
.page-langues{

  --page-w:1720px;
  --page-h:880px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1,h2{
    line-height: 1.5;
    margin: 0;
    font-weight: normal;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  h2{
    font-size: 80px;
    line-height: 1;
  }
  h1{
    font-size: 117px;
  }
  .languages{
    margin-top: 3vw;
  }


}
</style>