import Record from "@/models/Record";
import TranslatedField from "@/models/fields/TranslatedField";

export default class RoyaumeRecord extends Record{
    constructor(json) {
        super();
        this.json=json;
        this.id=json.id;
        this.identifiant=json.identifiant;
        this.royaume=new TranslatedField(json.royaume);
        this.titre1=new TranslatedField(json.titre1);
        this.titre2=new TranslatedField(json.titre2);
        this.titre3=new TranslatedField(json.titre3);
        this.paragraphe1=new TranslatedField(json.paragraphe1);
        this.paragraphe2=new TranslatedField(json.paragraphe2);
        this.paragraphe3=new TranslatedField(json.paragraphe3);
    }

    /**
     * Est-ce un royaume ou un autre type de page
     * @return {boolean}
     */
    get isRoyaume(){
        return !!this.royaume.fr;
    }

    /**
     * Est ce que c'est la page active ?
     * @return {boolean}
     */
    get isActive(){
        return String(window.$layout.currenRoute.params.identifiant) === String(this.identifiant);
    }


    get url(){
        if(this.isRoyaume){
            return `/${window.$lang.currentLocale.code}/royaume/${this.identifiant}`;
        }
        return `/${window.$lang.currentLocale.code}/record/${this.identifiant}`;
    }

}